import React, { useEffect } from "react";
import Cancel from "../../assets/icons/delete-1.svg";
import Umbrella from "../../assets/img/modal.svg";
import Reset from "../../assets/img/reset.svg";
import { Button } from "../customInputs/textBox";

import "./Modal.css";
import { Link } from "react-router-dom";
import { RoutePath } from "../../constants/routes";
import useApi from "../../services/hooks/useApi";
import { ResendEmail } from "../../services/apiservice/onboardingService";

type EmailVerificationModalProp = {
  user?: string;
  cancel: any;
  title?: string;
  btnText?: string;
  desc?: string;
  btn?: string;
};

const EmailVerificationModal = ({
  user,
  cancel,
  title,
  btnText,
  desc,
  btn,
}: EmailVerificationModalProp) => {
  const { fetch, loading, error, isSuccess } = useApi(() =>
    ResendEmail(user || "")
  );
  useEffect(() => {
    if (isSuccess) {
      cancel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
  return (
    <div className="backdrop">
      <div className="modal-container">
        <span className="cancel-btn">
          <img onClick={cancel} src={Cancel} alt="Modal" />
        </span>
        <div>
          <p className="text-danger mb-0 input-label">{error}</p>
          {btn ? (
            <img src={Reset} alt="Reset" className="w-50 mt-4 mb-4 reset-img" />
          ) : (
            <img src={Umbrella} alt="Umbrella" className="u-img mt-3 mb-2" />
          )}
          <h3 className="text-center">{title}</h3>
          {desc ? (
            <p className="mb-0 user-text">{desc}</p>
          ) : (
            <p className="mb-0 user-text">
              A link has been sent to '<span>{user}</span>'
            </p>
          )}
          <p className="small-text">
            Kindly follow this link to verify your email.
          </p>

          {!btn ? (
            // <Button
            //   id="modal-btn"
            //   text={btn ? btn : "Visit your email"}
            //   type="button"
            //   className="modal-btn"
            // />
            <Link
              to={`mailto:${user}`}
              className="edits-button text-center mt-0 mb-2 w-100"
            >
              {btn ? btn : "Visit your email"}
            </Link>
          ) : (
            <Link
              to={RoutePath.customer_login}
              className="edits-button text-center mt-0 mb-2 w-100"
            >
              Return to Login
            </Link>
          )}
          {btnText ? (
            <Button
              id="verify-btn"
              text={btnText}
              type="button"
              className="verify-btn"
              disabled={loading}
              onClick={() => fetch()}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default EmailVerificationModal;
