import { apiRoutes } from "../apiroutes";
import { RequestProps } from "../core";

const baseObject = { isAuth: false };

export const createUser = (): RequestProps => {
  return { url: apiRoutes.CreateUserEndpoint(), type: "post", ...baseObject };
};

export const LoginUser = (data: any): RequestProps => {
  return { url: apiRoutes.LoginUserEndpoint(), type: "post", data, ...baseObject };
};

export const ResendEmail =(email : string): RequestProps  =>{
  return { url: apiRoutes.ResendConfirmationEmail(email), type: "get", ...baseObject};
}