import React from "react";
import "./Sidebar.css";

import Home from "../../assets/img/home.png";
import Dashboard from "../../assets/img/dashboard.png";
import Profile from "../../assets/img/profile.png";
import CreateJob from "../../assets/img/createjob.png";
import Logout from "../../assets/img/logout.png";
import { Link } from "react-router-dom";
import { RoutePath } from "../../constants/routes";

interface SideBarPros {
  closeSideBar: any;
}

const Sidebar = ({ closeSideBar }: SideBarPros) => {
  return (
    <div className="sidebar-backdrop">
      <div className="sidebar-container">
        <div>
          <Link
            to={RoutePath.home}
            onClick={closeSideBar}
            className="sidebar-top-links"
          >
            <img src={Home} alt="Home" className="me-2" />
            <span>Home</span>
          </Link>

          <Link
            to={RoutePath.customer_dashboard}
            onClick={closeSideBar}
            className="sidebar-links"
          >
            <img src={Dashboard} alt="Dashboard" className="me-2" />
            <span>Dashboard</span>
          </Link>

          <Link
            to={RoutePath.customer_dashboard}
            onClick={closeSideBar}
            className="sidebar-links"
          >
            <img src={Profile} alt="Profile" className="me-2" />
            <span>Profile</span>
          </Link>

          <Link
            to={RoutePath.create_job}
            onClick={() => {
              closeSideBar();
              localStorage.setItem("routename", JSON.stringify("Create-Job"));
            }}
            className="sidebar-links"
          >
            <img src={CreateJob} alt="CreateJob" className="me-2" />
            <span>Create a Job</span>
          </Link>
        </div>
        <div>
          <Link
            to={RoutePath.customer_login}
            onClick={closeSideBar}
            className="logout-sidebar"
          >
            <img src={Logout} alt="Home" className="me-2" />
            <span>Logout</span>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
