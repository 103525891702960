import React from "react";
import "./Styles.css";

import Close from "../../../assets/img/close.png";
import { Button } from "../../../components/customInputs/textBox";

interface Props {
  close: any;
  accept?: () => void;
}

const AgreementBackdrop = ({ close, accept }: Props) => {
  return (
    <div className="agreement-backdrop">
      <div className="agreement-container">
        <span className="bg-warning cancel-backdrop" onClick={() => close()}>
          <img src={Close} alt="Cancel" className="cancel-img" />
        </span>

        <div className="agreement">
          <div className="download-container">
            <p>Service Level Agreement</p>

            <div className="btn-contain">
              <Button
                id="submit"
                type="submit"
                text="Download"
                className="onboard-button download-btn text-black"
                onClick={() => {}}
              />
            </div>
          </div>

          <div className="btn-groups">
            <Button
              id="submit"
              type="submit"
              text="Decline"
              className="onboard-button decline-btn text-black"
              onClick={() => {}}
            />

            <Button
              id="submit"
              type="submit"
              text="Accept"
              className="onboard-button accept-btn"
              onClick={() => {
                accept && accept();
                close();
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AgreementBackdrop;
