import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../constants/routes";
import { Button, TextBox } from "../../../components/customInputs/textBox";
import EmailVerificationModal from "../../../components/EmailVerificationModal";
import "../../customer/customer.css";
import backIcon from "../../../assets/icons/back-1.svg";

interface Props {
  forgotPassword: any;
}

const ForgotPassword = ({ forgotPassword }: Props) => {
  const [email, setEmail] = useState<string>("");
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isValid, setIsValid] = useState(false);
  const emailValidation = (email: string) => {
    const regex = new RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{3,})$/i
    );
    return !(!email || regex.test(email) === false);
  };

  const onHandleChange = (email: string) => {
    const isEmailValid = emailValidation(email);
    setIsValid(isEmailValid);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  useEffect(() => {
    forgotPassword();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="signup-container">
      {showModal && (
        <EmailVerificationModal
          user={email}
          cancel={() => setShowModal(!showModal)}
          title={"Password reset link sent."}
          btnText={"Resend password reset link"}
        />
      )}
      <span onClick={() => {}} className="btn desktop">
        <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
        Back
      </span>
      <div className="form-container">
        <h1 className="form-header fs-1 mb-0">
          Reset password
          <span className="header-dot">.</span>
        </h1>
        <p className="header-instruction mb-0">
          kindly enter the email associated with your account and we would send
          a link to your email address for you to easily reset your password.
        </p>
        <form className="onboard-form">
          <p className="text-danger mb-0 input-label"></p>
          <div className="group-input">
            <label
              className="input-label"
              aria-label="Email Address"
              label-for="email"
            >
              Email address
            </label>
            <TextBox
              placeholder="Enter your email address"
              type="email"
              id="email"
              required
              onChange={(e: any) => {
                setEmail(e.target.value);
                onHandleChange(e.target.value);
              }}
              value={email}
            />
          </div>
          <Button
            id="submit"
            type="submit"
            text="Send Password Reset Link"
            className="onboard-button"
            onClick={handleClick}
            disabled={!isValid}
          />
        </form>
        <div className="base-question forgot-base">
          <p>
            Do not own an account?{" "}
            <Link
              to={RoutePath.customer_signup}
              className="text-black text-decoration-none"
            >
              <span>Signup</span>
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
