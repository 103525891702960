import React, {  useContext } from "react";
import "./container.css";
import { CalculatorContext} from "./containerContext";
import Logo from "../../assets/img/logo.svg";
import delIcon from "../../assets/icons/delete-1.svg";
import CalcImage from "../../assets/img/calc-image.png";
import { CalculatorStages, RoutePath } from "../../constants/routes";
import backIcon from '../../assets/icons/back-1.svg';
import { Link } from "react-router-dom";

const CalculatorContainer = () => {
  const contextObj = useContext(CalculatorContext);
 const  { pageNumber, updateContext} = contextObj;
  return (
    <div className="calc-container">
      <header className="calc-header">
        <img src={Logo} alt="Mysolarbid's Logo" />
      </header>
      <div className="row main-body">
        <div className="col-lg-2 col-sm-1 cancel-div">
          {pageNumber === 0 ?<Link to={RoutePath.home} className="btn cancel">
            <img className="m-2 mt-0" src={delIcon} alt="delete Icon" />
            Cancel
          </Link> : <span onClick={()=>updateContext({...contextObj, pageNumber: pageNumber - 1})} className="btn">
                   <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
                   Back
            </span>}
        </div>
        <div className="col-lg-8 col-sm-10 center-section">
          <img className="calc-image" src={CalcImage} alt="calc" />
           {CalculatorStages[pageNumber].component}
         </div>
        <div className="col-lg-2 col-sm-1 not-mobile text-center">
          {pageNumber < CalculatorStages.length - 1 && 
           <div className="circle-dark">
               {CalculatorStages[pageNumber].number }/{CalculatorStages.length - 1}
           </div> 
           }
        </div>
      </div>
    </div>
  );
};
export default CalculatorContainer;
