import React, {
  FunctionComponent,
  useState,
  useContext,
  useRef,
  useMemo,
} from "react";
import { CalculatorContext } from "../../components/calculatorContainer/containerContext";

import "./calculator.css";
import { Button } from "../../components/customInputs/textBox";
// import AcImage from "../../assets/icons/appliance/ac.svg";
// import pcImage from "../../assets/icons/appliance/pc.svg";
// import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Skeleton from "@mui/material/Skeleton";
import ReactElasticCarousel from "react-elastic-carousel";
import Appliance from "../../models/Appliance";
import useWindowDimensions from "../../utility/hooks";

// import Carousel from "react-multi-carousel";
// import "react-multi-carousel/lib/styles.css";

export const CalcApplianceComponent: FunctionComponent = () => {
  const contextObject = useContext(CalculatorContext);
  const { updateContext, pageNumber, appliances } = contextObject;
  const [selectedAppliances, setSelectedItems] = useState<Array<Appliance>>(
    contextObject?.selectedAppliances || []
  );
  const slideRef = useRef<any>();
  const { width } = useWindowDimensions();

  let Appliances = useMemo(() => {
    return appliances?.data?.payload?.map(
      ({
        id,
        name,
        powerInWatts,
        iconPath,
      }: {
        id: string;
        name: string;
        powerInWatts: number;
        iconPath: string;
      }) => new Appliance(id, name, powerInWatts, iconPath)
    );
  }, [appliances?.data]);

  const handleItemClick = (id: string) => {
    let valueIndex = selectedAppliances.findIndex((x) => x.applianceId === id);
    let items = [...selectedAppliances];
    valueIndex >= 0
      ? items.splice(valueIndex, 1)
      : (items = [
          ...items,
          Appliances.find((x: Appliance) => x.applianceId === id),
        ]);
    setSelectedItems([...items]);
  };

  const handleNextSlide = () => {
    slideRef.current.slideNext();
  };

  const handleNext = () => {
    updateContext({
      ...contextObject,
      selectedAppliances,
      pageNumber: pageNumber + 1,
    });
  };

  return (
    <div className="innersector-container">
      <h1 className="question font-48 mt-2">
        Which appliances would you like to power?
      </h1>
      <p className="sub-question">
        Select critical appliances you want to power with solar.
      </p>
      {/* <div className="appliance-box">{applianceList()}</div> */}

      <ReactElasticCarousel
        isRTL={false}
        showArrows={false}
        itemsToShow={width <= 500 ? 2.5 : 6.5}
        ref={slideRef}
        renderPagination={({ pages, activePage, onClick }) => (
          <div className="pagination-container">
            <div style={{ float: "right" }}>
              {pages?.map((page: any) => {
                const isActivePage = activePage === page;
                return (
                  <span
                    key={page}
                    onClick={() => onClick(page)}
                    className={isActivePage ? "dot active" : "dot"}
                  ></span>
                );
              })}
              <ArrowForwardIcon
                focusable={true}
                className="nextSlide-button"
                onClick={handleNextSlide}
              />
            </div>
          </div>
        )}
      >
        {Appliances?.map(
          ({ src, appliance, applianceId }: Appliance, index: number) => (
            <div
              key={index}
              onClick={() => handleItemClick(applianceId)}
              className={`card appliance-item ${
                selectedAppliances?.findIndex(
                  (x) => x.applianceId === applianceId
                ) >= 0
                  ? "active"
                  : ""
              }`}
            >
              <div className="card-body text-center">
                <img
                  src={`${process.env.REACT_APP_BASEURL}${src}`}
                  alt={appliance}
                />
                <label className="mt-3 d-block">{appliance}</label>
              </div>
            </div>
          )
        )}
        {appliances.loading &&
          [...Array(6)].map((index) => (
            <Skeleton
              key={index}
              sx={{ padding: 5 }}
              variant="rectangular"
              width={150}
              height={160}
            />
          ))}
      </ReactElasticCarousel>
      <Button
        text="Next"
        type="button"
        id="appliance-button"
        className="calculator-button appliance-button"
        onClick={handleNext}
        disabled={selectedAppliances.length >= 1 ? false : true}
      />
    </div>
  );
};

export default CalcApplianceComponent;
