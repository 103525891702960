import React from "react";
import Panel from "../../assets/img/solar-panel.png";
import Inverter from "../../assets/img/accumulator.png";
import Battery from "../../assets/img/battery.png";
import Controller from "../../assets/img/controller.png";
import Deposit from "../../assets/img/deposit.png";
import Purchase from "../../assets/img/purchase.png";
import Payment from "../../assets/img/confirm-payment.png";
import { Button } from "../../components/customInputs/textBox";

const ConfirmQuote = () => {
  return (
    <div className="admin-container">
      <p className="text-center fs-3">Confirmation</p>

      <div className="confirm-quote-container">
        <div className="quote-table-container mt-4">
          <div className="confirm-first-column">
            <p className="quote-title"></p>
          </div>
          <table>
            <tr>
              <td className="confirm-second-column">
                <p>Quote Options</p>
              </td>
              <td className="confirm-third-column">
                <p>Solar Panel</p>
              </td>
              <td className="confirm-fourth-column">
                <p>Without Solar Panel</p>
              </td>
            </tr>
          </table>
        </div>

        <div className="confirm-quote-underline"></div>

        <div className="quote-table-container my-4">
          <div className="confirm-first-column">
            <img src={Panel} alt="Solar Panel" className="confirm-quote-img" />
          </div>
          <table>
            <tr>
              <td className="confirm-second-column">Solar Panels</td>
              <td className="confirm-third-column">15 pcs of 310 Wp</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Solar Panel Type</td>
              <td className="confirm-third-column">Monocrystalline</td>
              <td className="confirm-fourth-column">None</td>
            </tr>
            <tr>
              <td className="confirm-second-column">
                Solar panel manufacturer
              </td>
              <td className="confirm-third-column">Auxano</td>
            </tr>
          </table>
        </div>

        <div className="confirm-quote-underline"></div>

        <div className="quote-table-container my-4">
          <div className="confirm-first-column">
            <img src={Inverter} alt="Inverter" className="confirm-quote-img" />
          </div>

          <table>
            <tr>
              <td className="confirm-second-column">Inverter Quantity</td>
              <td className="confirm-third-column">2</td>
              <td className="confirm-fourth-column">2</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Inverter type</td>
              <td className="confirm-third-column">Hybrid</td>
              <td className="confirm-fourth-column">Hybrid</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Inverter manufacturer</td>
              <td className="confirm-third-column">Luminous</td>
              <td className="confirm-fourth-column">Luminous</td>
            </tr>
          </table>
        </div>

        <div className="confirm-quote-underline"></div>

        <div className="quote-table-container my-4">
          <div className="confirm-first-column">
            <img src={Battery} alt="Battery" className="confirm-quote-img" />
          </div>

          <table>
            <tr>
              <td className="confirm-second-column">Battery Quantity</td>
              <td className="confirm-third-column">12</td>
              <td className="confirm-fourth-column">10</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Battery voltage</td>
              <td className="confirm-third-column">12v / 200Ah</td>
              <td className="confirm-fourth-column">12v / 200Ah</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Battery type</td>
              <td className="confirm-third-column">AGM</td>
              <td className="confirm-fourth-column">Lithium ion</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Battery manufacturer</td>
              <td className="confirm-third-column">Luminous</td>
              <td className="confirm-fourth-column">Luminous</td>
            </tr>
          </table>
        </div>

        <div className="confirm-quote-underline"></div>

        <div className="quote-table-container my-4">
          <div className="confirm-first-column">
            <img
              src={Controller}
              alt="Controller"
              className="confirm-quote-img"
            />
          </div>

          <table>
            <tr>
              <td className="confirm-second-column">
                Charge controller quantity
              </td>
              <td className="confirm-third-column">1</td>
            </tr>
            <tr>
              <td className="confirm-second-column">
                Charge controller rating
              </td>
              <td className="confirm-third-column">60 A</td>
              <td className="confirm-fourth-column">None</td>
            </tr>
            <tr>
              <td className="confirm-second-column"> Charge controller type</td>
              <td className="confirm-third-column">MPPT</td>
            </tr>
            <tr>
              <td className="confirm-second-column">
                Charge controller manufacturer
              </td>
              <td className="confirm-third-column">Jinko</td>
            </tr>
          </table>
        </div>

        <div className="confirm-quote-underline"></div>

        <div className="quote-table-container my-4">
          <div className="confirm-first-column">
            <img src={Deposit} alt="Deposit" className="confirm-quote-img" />
          </div>

          <table>
            <tr>
              <td className="confirm-second-column">Initial deposit ratio</td>
              <td className="confirm-third-column">80%</td>
              <td className="confirm-fourth-column">70%</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Final deposit ratio</td>
              <td className="confirm-third-column">20%</td>
              <td className="confirm-fourth-column">30%</td>
            </tr>
          </table>
        </div>

        <div className="confirm-quote-underline"></div>

        <div className="quote-table-container my-4">
          <div className="confirm-first-column">
            <img src={Purchase} alt="Purchase" className="confirm-quote-img" />
          </div>

          <table>
            <caption className="fs-5 pt-0">Outright purchase</caption>
            <tr>
              <td className="confirm-second-column">Total cost(incl. VAT)</td>
              <td className="confirm-third-column">3,311,000.00</td>
              <td className="confirm-fourth-column">2,268,250.00</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Payment term</td>
              <td className="confirm-third-column">80% : 20%</td>
              <td className="confirm-fourth-column">70% : 30%</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Initial deposit</td>
              <td className="confirm-third-column">2,648,800.00</td>
              <td className="confirm-fourth-column">1,814,600.00</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Final deposit</td>
              <td className="confirm-third-column">662,200.00</td>
              <td className="confirm-fourth-column">453,650.00</td>
            </tr>
            <tr>
              <td className="confirm-second-column">
                Maintenance fee (optional)
              </td>
              <td className="confirm-third-column">75,000.00</td>
              <td className="confirm-fourth-column">75,000.00</td>
            </tr>
          </table>
        </div>

        <div className="confirm-quote-underline"></div>

        <div className="quote-table-container my-4">
          <div className="confirm-first-column">
            <img src={Payment} alt="Payment" className="confirm-quote-img" />
          </div>

          <table>
            <caption className="fs-5 p-0">Payment plan</caption>
            <caption className="pt-0">Total cost (incl. VAT)</caption>
            <tr>
              <td className="confirm-second-column">Monthly payments</td>
              <td className="confirm-third-column">330,000.00</td>
              <td className="confirm-fourth-column">165,000.00</td>
            </tr>
            <tr>
              <td className="confirm-second-column">Initial payments</td>
              <td className="confirm-third-column">990,000.00</td>
              <td className="confirm-fourth-column">495,000.00</td>
            </tr>
            <tr>
              <td className="confirm-second-column">
                Payment plan term (months)
              </td>
              <td className="confirm-third-column">60</td>
              <td className="confirm-fourth-column">60</td>
            </tr>
            <tr>
              <td className="confirm-second-column">
                Maintenance fee (optional)
              </td>
              <td className="confirm-third-column">20,000.00</td>
              <td className="confirm-fourth-column">20,000.00</td>
            </tr>
          </table>
        </div>
      </div>

      <Button
        id="submit"
        type="submit"
        text="Submit"
        className="onboard-button mt-4 quote-submit"
        onClick={() => {}}
      />
    </div>
  );
};

export default ConfirmQuote;
