import React, { useState, useEffect, useMemo, useContext } from "react";
import "./admin.css";
import useApi from "../../services/hooks/useApi";
import { getAllAppliances } from "../../services/apiservice/generalService";
import Appliance from "../../models/Appliance";
import SingleAppliance from "../../components/Appliances";
import { Button, TextBox } from "../../components/customInputs/textBox";
import CreateQuoteBackdrop from "../../components/AdminJobs/CreateQuoteBackdrop";
import { CreateQuoteContext } from "../../components/AdminJobs/CreateQuoteContext/CreateQuoteContainer";

const CreateQuote1 = () => {
  const contextObject = useContext(CreateQuoteContext);
  const { updateContext, pageNumber } = contextObject;
  const [quoteName, setQuoteName] = useState("");

  const [showModal, setShowModal] = useState(false);

  const [firstColumn, setFirstColumn] = useState("With Solar Panel");
  const [secondColumn, setSecondColumn] = useState("Without Solar Panel");

  const [systemMaxOut1, setSystemMaxOut] = useState("");
  const [systemMaxOut2, setSystemMaxOut2] = useState("");

  const [powerFactor1, setPowerFactor1] = useState("");
  const [powerFactor2, setPowerFactor2] = useState("");

  const [actualOutput1, setActualOutput1] = useState("");
  const [actualOutput2, setActualOutput2] = useState("");

  const [remoteMonitoringSystem1, setRemoteMonitoringSystem1] =
    useState("Select an option");
  const [remoteMonitoringSystem2, setRemoteMonitoringSystem2] =
    useState("Select an option");

  const [paymentPlanAvailable1, setPaymentPlanAvailable1] =
    useState("Select an Option");
  const [paymentPlanAvailable2, setPaymentPlanAvailable2] =
    useState("Select an Option");

  const [paymentOptions1, setPaymentOptions1] = useState("Select an option");
  const [paymentOptions2, setPaymentOptions2] = useState("Select an option");

  const [paymentStructure1, setPaymentStructure1] = useState("");
  const [paymentStructure2, setPaymentStructure2] = useState("");

  const [projectDuration1, setProjectDuration1] = useState("");
  const [projectDuration2, setProjectDuration2] = useState("");

  const [projectStart1, setProjectStart1] = useState("Select an option");
  const [projectStart2, setProjectStart2] = useState("Select an option");

  const [overallSystem1, setOverallSystem1] = useState("Select an option");
  const [overallSystem2, setOverallSystem2] = useState("Select an option");

  const [service1, setService1] = useState("");
  const [service2, setService2] = useState("");

  const [whyUs, setWhyUs] = useState("");

  useEffect(() => {
    setShowModal(true);
  }, []);

  const { data, fetch } = useApi<
    Array<{
      id: string;
      name: string;
      powerInWatts: number;
      iconPath: string;
      quantity: number;
    }>
  >(getAllAppliances);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let Appliances = useMemo(() => {
    return data?.payload?.map(
      ({
        id,
        name,
        powerInWatts,
        iconPath,
        quantity = 1,
      }: {
        id: string;
        name: string;
        powerInWatts: number;
        iconPath: string;
        quantity: number;
      }) => new Appliance(id, name, powerInWatts, iconPath, quantity)
    );
  }, [data]);

  const submitQuote1 = (e: any) => {
    e.preventDefault();
    updateContext({
      ...contextObject,
      quoteBasic: [
        {
          quoteOption: parseInt(firstColumn),
          sysMaxOutput: parseInt(systemMaxOut1),
          powerFactor: parseInt(powerFactor1),
          actualOutput: parseInt(actualOutput1),
          remoteMonitor: remoteMonitoringSystem1 === "Yes" ? true : false,
          paymentPlan: paymentPlanAvailable1 === "Yes" ? true : false,
          paymentOptions: parseInt(paymentOptions1),
          paymentStructure: paymentStructure1,
          projectDuration: projectDuration1,
          startDate: projectStart1,
          systemWarranty: overallSystem1,
          maintenanceWarranty: service1,
          labourWorkers: 0,
          labourTransportation: 0,
          labourTotalCost: 0,
          jobGrandTotal: 0,
        },
      ],
      pageNumber: pageNumber + 1,
    });
  };

  return (
    <>
      <div className="admin-container">
        {showModal && <CreateQuoteBackdrop close={() => setShowModal(false)} />}
        <header>
          <span className="project-name-container">
            <span className="project-header">
              <p>Project name</p>
              <span className="project-underline"></span>
            </span>
            :<span className="project-name">Femi's Residence</span>
          </span>
          <span className="project-name-container decs-width mt-3">
            <span className="project-header">
              <p>Project Description</p>
              <span className="project-underline"></span>
            </span>
            :
            <span className="project-name">
              A 5 bedroom residence to power some appliances when there is no
              light.
            </span>
          </span>
        </header>

        <div className="quote-appliances">
          {Appliances?.map(
            (
              { src, appliance, applianceId, quantity }: Appliance,
              index: number
            ) => (
              <SingleAppliance
                name={appliance}
                url={src}
                id={applianceId}
                key={index}
                width={"w-75"}
                quantity={quantity}
              />
            )
          )}
        </div>

        <div>
          <p className="quote-info">
            You can only create a minimum of 1 and a maximum of 3 quotes
          </p>
          <div className="createquote-name">
            <p className="fs-4">Quote Name</p>
            <div className="d-flex">
              <TextBox
                placeholder=""
                type="text"
                id="text"
                className="input-width me-2 quote-name-input"
                required
                onChange={(e: any) => {
                  setQuoteName(e.target.value);
                }}
                value={quoteName}
              />
            </div>
          </div>
        </div>

        <div className="quote-inputs-container">
          <div className="quote-inputs-details">
            <div className="quote-install-options">
              <p className="first-header mb-0 first-column">Quote Options</p>
              <select
                value={firstColumn}
                className="form-select create-quote-dropdown second-column"
                aria-label="quotes"
                onChange={(e: any) => setFirstColumn(e.target.value)}
              >
                <option value="1">With Solar Panel</option>
                <option value="2">Without Solar Panel</option>
                <option value="3">Only Batteries</option>
                <option value="4">Only Inverter</option>
                <option value="5">Only Panels</option>
              </select>

              <select
                value={secondColumn}
                className="form-select create-quote-dropdown third-column"
                aria-label="quotes"
                onChange={(e: any) => setSecondColumn(e.target.value)}
              >
                <option value="1">With Solar Panel</option>
                <option value="2">Without Solar Panel</option>
                <option value="3">Only Batteries</option>
                <option value="4">Only Inverter</option>
                <option value="5">Only Panels</option>
              </select>
            </div>

            <p className="my-4 fs-4">Basic Details</p>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  System Max Output <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 25 KVA"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut(e.target.value);
                  }}
                  value={systemMaxOut1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 25 KVA"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Power Factor(&lt;1) <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 25 KVA"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setPowerFactor1(e.target.value);
                  }}
                  value={powerFactor1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 25 KVA"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setPowerFactor2(e.target.value);
                  }}
                  value={powerFactor2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">Actual Output</p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 25 KVA"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setActualOutput1(e.target.value);
                  }}
                  value={actualOutput1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 25 KVA"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setActualOutput2(e.target.value);
                  }}
                  value={actualOutput2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Remote monitoring system <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={remoteMonitoringSystem1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) =>
                    setRemoteMonitoringSystem1(e.target.value)
                  }
                >
                  <option value="">Select an option</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={remoteMonitoringSystem2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) =>
                    setRemoteMonitoringSystem2(e.target.value)
                  }
                >
                  <option value="">Select an option</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Payment Plan Available <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={paymentPlanAvailable1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) =>
                    setPaymentPlanAvailable1(e.target.value)
                  }
                >
                  <option value="">Select an option</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={paymentPlanAvailable2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) =>
                    setPaymentPlanAvailable2(e.target.value)
                  }
                >
                  <option value="">Select an option</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p>Payment Options</p>
              </div>

              <div className="second-column">
                <select
                  value={paymentOptions1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setPaymentOptions1(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="1">Pay over 3 months</option>
                  <option value="2">Pay over 6 months</option>
                  <option value="3">Pay over 12 months</option>
                  <option value="4">Pay over 24 months</option>
                  <option value="5">Others</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={paymentOptions2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setPaymentOptions2(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="1">Pay over 3 months</option>
                  <option value="2">Pay over 6 months</option>
                  <option value="3">Pay over 12 months</option>
                  <option value="4">Pay over 24 months</option>
                  <option value="5">Others</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Payment structure <span>*</span>
                  <br />
                  (for outright purchase)
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 60% : 40%"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setPaymentStructure1(e.target.value);
                  }}
                  value={paymentStructure1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 60% : 40%"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setPaymentStructure2(e.target.value);
                  }}
                  value={paymentStructure2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Project Duration<span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 60% : 40%"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setProjectDuration1(e.target.value);
                  }}
                  value={projectDuration1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 60% : 40%"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setProjectDuration2(e.target.value);
                  }}
                  value={projectDuration2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Project Start date <span>*</span>
                  <br />
                  (after payment)
                </p>
              </div>

              <div className="second-column">
                <select
                  value={projectStart1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setProjectStart1(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">&gt;5</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={projectStart2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setProjectStart2(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">&gt;5</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Overall system component <span>*</span>
                  <br />
                  (warranty)
                </p>
              </div>

              <div className="second-column">
                <select
                  value={overallSystem1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setOverallSystem1(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 year</option>
                  <option value="2">2 years</option>
                  <option value="3">3 years</option>
                  <option value="4">4 years</option>
                  <option value="5">5 years</option>
                  <option value="6">&gt;5 years</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={overallSystem2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setOverallSystem2(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 year</option>
                  <option value="2">2 years</option>
                  <option value="3">3 years</option>
                  <option value="4">4 years</option>
                  <option value="5">5 years</option>
                  <option value="6">&gt;5 years</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Service and maintenance <span>*</span>
                  <br />
                  (warranty)
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 3 months"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setService1(e.target.value);
                  }}
                  value={service1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 3 months"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setService2(e.target.value);
                  }}
                  value={service2}
                />
              </div>
            </div>

            <div className="quote-table-container why-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Why choose us?</p>
              </div>

              <div className="second-column quote-why">
                <TextBox
                  placeholder="briefly explain why the customer should choose you

(50 words maximum)"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setWhyUs(e.target.value);
                  }}
                  value={whyUs}
                />
              </div>
            </div>
            <Button
              id="submit"
              type="submit"
              text="Save and Continue"
              className="onboard-button mt-5 quote-submit"
              onClick={submitQuote1}
              // disabled={
              //   systemMaxOut1 &&
              //   powerFactor1 &&
              //   remoteMonitoringSystem1 &&
              //   paymentPlanAvailable1 &&
              //   paymentStructure1 &&
              //   projectDuration1 &&
              //   projectStart1 &&
              //   overallSystem1 &&
              //   service1
              //     ? false
              //     : true
              // }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuote1;
