import React, { useState, useContext } from "react";
import "./admin.css";
import { Button, TextBox } from "../../components/customInputs/textBox";
import { CreateQuoteContext } from "../../components/AdminJobs/CreateQuoteContext/CreateQuoteContainer";

const CreateQuote3 = () => {
  const contextObject = useContext(CreateQuoteContext);
  const { updateContext, pageNumber } = contextObject;
  const [quoteName, setQuoteName] = useState("");
  const [secondColumn, setSecondColumn] = useState("Without Solar Panel");
  const [systemMaxOut1, setSystemMaxOut] = useState("");
  const [systemMaxOut2, setSystemMaxOut2] = useState("");

  const [disableField1, SetDisableField1] = useState(false);
  const [disableField2, SetDisableField2] = useState(false);
  const [disableInverterField1, SetDisableInverterField1] = useState(false);
  const [disableInverterField2, SetDisableInverterField2] = useState(false);

  const submitQuote3 = (e: any) => {
    e.preventDefault();
    updateContext({
      ...contextObject,
      pageNumber: pageNumber + 1,
    });
  };
  return (
    <>
      <div className="admin-container">
        <div className="quote-inputs-container">
          <div className="quote-inputs-details">
            <div className="quote-install-options">
              <p className="first-header mb-0 first-column">Quote Options</p>

              <div className="second-column">
                <TextBox
                  placeholder="Solar Panel"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setQuoteName(e.target.value);
                  }}
                  value={quoteName}
                  disabled
                />
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="Without Solar Panel"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut(e.target.value);
                  }}
                  value={systemMaxOut1}
                  disabled
                />
              </div>
            </div>

            <div className="quote-install-options mt-4">
              <p className="first-header mb-0 first-column">Batteries</p>

              <div className="second-column text-center">
                <input
                  type="checkbox"
                  name="colFirst"
                  onChange={(e: any) => {
                    SetDisableField1(!disableField1);
                  }}
                  checked={disableField1}
                />
              </div>

              <div className="third-column text-center">
                <input
                  type="checkbox"
                  name="colSecond"
                  onChange={(e: any) => {
                    SetDisableField2(!disableField2);
                  }}
                  checked={disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Battery manufacturer <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g Luminous"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut(e.target.value);
                  }}
                  value={systemMaxOut1}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g Luminous"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Battery type <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">AGM</option>
                  <option value="2">Lithium ion</option>
                  <option value="3">Flooded Lead Acid</option>
                  <option value="4">Flow Battery</option>
                  <option value="5">Others</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">AGM</option>
                  <option value="2">Lithium ion</option>
                  <option value="3">Flooded Lead Acid</option>
                  <option value="4">Flow Battery</option>
                  <option value="5">Others</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Battery Design <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">Deep Cycle</option>
                  <option value="2">Shallow Cycle</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">Deep Cycle</option>
                  <option value="2">Shallow Cycle</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Battery Voltage <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 12 Volts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 12 Volts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Number of backup hours <span>*</span>
                  <br />
                  (at peak load)
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 12 hours"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 12 hours"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Battery Capacity <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 220 Kwh"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 220 Kwh"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Battery Quantity <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 5"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 5"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Battery Unit Price <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Battery warranty <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Total battery cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-underline mt-4"></div>

            <div className="quote-install-options mt-4">
              <p className="first-header mb-0 first-column">
                Charge controller
              </p>

              <div className="second-column text-center">
                <input
                  type="checkbox"
                  name="colFirst"
                  onChange={(e: any) => {
                    SetDisableInverterField1(!disableInverterField1);
                  }}
                  checked={disableInverterField1}
                />
              </div>

              <div className="third-column text-center">
                <input
                  type="checkbox"
                  name="colSecond"
                  onChange={(e: any) => {
                    SetDisableInverterField2(!disableInverterField2);
                  }}
                  checked={disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Charge controller manufacturer <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g jinko"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g jinko"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Charge controller type <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">PWM</option>
                  <option value="2">PWM (embedded in inverter)</option>
                  <option value="3">MPPT</option>
                  <option value="4">MPPT (embedded in inverter)</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">PWM</option>
                  <option value="2">PWM (embedded in inverter)</option>
                  <option value="3">MPPT</option>
                  <option value="4">MPPT (embedded in inverter)</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Charge controller voltage <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 24 Volts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 24 Volts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Charge controller rating <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 60 Amp"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 60 Amp"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Charge controller quantity <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 1"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 1"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Charge controller unit price <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Charge controller warranty <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableInverterField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableInverterField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Total charge controller cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <Button
              id="submit"
              type="submit"
              text="Save and Continue"
              className="onboard-button mt-5 quote-submit"
              onClick={submitQuote3}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuote3;
