import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import backIcon from "../../../assets/icons/back-1.svg";
import { Button } from "../../../components/customInputs/textBox";
import { Installer, QuoteReceivedData } from "../../../constants/dummy";
import { RoutePath } from "../../../constants/routes";

import QuoteProgress from "../../../components/Quote/QuoteProgress";
import CalenderIcon from "../../../assets/img/calendar-clock.svg";
import QuoteFilled from "../../../assets/img/quote-accepted.svg";
import Quote from "../../../assets/img/quote-unaccepted.svg";
import Arrow from "../../../assets/img/Arrow.svg";
import QuoteInstaller from "../../../components/Quote/QuoteInstaller";
import QuoteBackdrop from "../../../components/Quote/QuoteBackdrop/QuoteBackdrop";
import AgreementBackdrop from "../../../components/Quote/AgreementBackdrop/AgreementBackdrop";
import QuoteReceivedModal from "../../../components/Quote/QuoteReceivedModal";
import QuoteComplete from "../../../components/Quote/quoteComplete";
import QuotePayment from "../../../components/Quote/quoteDetails";
import useApi from "../../../services/hooks/useApi";
import {
  DeleteJob,
  GetCustomerJobByID,
} from "../../../services/apiservice/generalService";
import { AppToast } from "../../../components/Toast/toast";
import { CustomJob } from "../../../models/Job";
import { formatDateAndTime } from "../../../utility";

const QuoteDetails = () => {
  const [notificationOption, setnotificationOption] = useState("None");
  const [installer, setInstaller] = useState("");
  const [showQuote, setShowQuote] = useState(false);
  const [showAgreement, setShowAgreement] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const navigate = useNavigate();
  let id = JSON.parse(localStorage.getItem("jobId") as string);
  localStorage.setItem("jobID", JSON.stringify(id));

  const { loading, fetch, isSuccess, isFailed } = useApi(() => DeleteJob(id));
  const {
    fetch: GetCustomerJobById,
    data: Job,
    loading: JobLoading,
  } = useApi<CustomJob>(() => GetCustomerJobByID(id));

  useEffect(() => {
    GetCustomerJobById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isFailed) setShowDeleteModal(false);
  }, [isFailed]);

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutePath.customer_dashboard);
      AppToast({
        type: "success",
        message: `Job Deleted Successfully`,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <div>
      {showQuote && <QuoteBackdrop close={() => setShowQuote(false)} />}
      {showAgreement && (
        <AgreementBackdrop close={() => setShowAgreement(false)} />
      )}
      {showModal && (
        <QuoteReceivedModal
          user="You have successfully accepted your favourite installer"
          cancel={() => setShowModal(false)}
          desc="A message has been sent"
          btnText="Ok"
        />
      )}
      {showDeleteModal && (
        <QuoteReceivedModal
          user="Are you sure you want to delete this Job?"
          cancel={() => setShowDeleteModal(false)}
          clicked={() => fetch()}
          btnText="Proceed"
          desc="You can reach out to us if you encounter any problem"
        />
      )}
      <div className="Quote-Payment-Container">
        <span onClick={() => navigate(-1)} className="btn desktop">
          <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
          Back
        </span>

        <div className="Quote-details">
          {JobLoading ? (
            <div className="skeleton">
              <Skeleton
                // key={index}
                sx={{ padding: 5, marginRight: 5, marginBottom: 5 }}
                variant="rectangular"
                width={"482px"}
                height={"260px"}
              />
            </div>
          ) : (
            <div className="border quote mb-2 border-warning">
              <img src={CalenderIcon} alt="Calender" className="calender" />
              {Job?.payload && (
                <span className="date-text">
                  {formatDateAndTime(Job?.payload?.postedDate)}
                </span>
              )}
              <p className="quote-status">Posted</p>

              <div className="quote-info">
                <h3 className="mb-0 quote-name">
                  {Job?.payload?.projectTitle}
                </h3>
                <p className="project-text">
                  Project ID: {Job?.payload?.projectId}
                </p>
              </div>

              <div>
                <img
                  src={QuoteFilled}
                  alt="Quote Accepted"
                  className="quote-icon"
                />{" "}
                <span className="quote-text">Quote received</span>{" "}
                <img src={Arrow} alt="Arrow" className="arrow" />{" "}
                <img
                  src={Quote}
                  alt="Quote Unaccepted"
                  className="quote-icon"
                />{" "}
                <span className="quote-text">Quote unaccepted</span>
              </div>
            </div>
          )}

          <div className="Quote-buttons">
            <Link
              to={RoutePath.create_job}
              // state={{ routename: "Edit-Job" }}
              onClick={() =>
                localStorage.setItem("routename", JSON.stringify("Edit-Job"))
              }
              className="edits-button text-center mt-0 mb-2"
            >
              Edit Job
            </Link>

            <Button
              id="submit"
              type="submit"
              text="Delete Job"
              className="quote-delete-button bg-warning text-black"
              onClick={() => setShowDeleteModal(true)}
              disabled={loading}
            />
          </div>
        </div>

        <div className="Quote-Progress mt-4">
          {QuoteReceivedData.map((data) => (
            <QuoteProgress
              key={data.id}
              name={data.name}
              imgUrl={data.imgUrl}
              active={data.active}
            />
          ))}
        </div>

        <p className="ms-3 fs-5 mt-4">Installers</p>
        <div className="installer-conatainer">
          {Installer.map((installer) => (
            <QuoteInstaller
              name={installer.name}
              rating={installer.star}
              solar={installer.solarPanel}
              withoutSolar={installer.withoutSolarPanel}
              key={installer.id}
              onClick={() => setShowQuote(true)}
            />
          ))}
        </div>

        <div className="details-container">
          <div className="radio-container">
            <p className="radio-label">Choose an Installer</p>
            <div className="radio-btns">
              <input
                type="radio"
                id="one"
                name="installer"
                value="Installer 1"
                onClick={() => setInstaller("Installer 1")}
              />
              <label htmlFor="html">Installer 1</label>
              <br />
              <input
                type="radio"
                id="two"
                name="installer"
                value="Installer 2"
                onClick={() => setInstaller("Installer 2")}
              />
              <label htmlFor="two">Installer 2</label>
              <br />
              <input
                type="radio"
                id="three"
                name="installer"
                value="Installer 3"
                onClick={() => setInstaller("Installer 3")}
              />
              <label htmlFor="three">Installer 3</label>
              <br />
            </div>
          </div>
          <div className="quote-menu">
            <p className="dropdown-label">Select a financier (Optional)</p>

            <div className="mt-1 quote-dropdown-container">
              <select
                value={notificationOption}
                className="form-select quote-dropdown"
                aria-label="Payment Option"
                onChange={(e: any) => setnotificationOption(e.target.value)}
              >
                <option value="None">None</option>
                <option value="Finance A">Finance A</option>
                <option value="Finance B">Finance B</option>
                <option value="Finance C">Finance C</option>
              </select>
            </div>
          </div>
        </div>

        <div>
          <p
            className="text-center text-decoration-underline pointer"
            onClick={() => setShowAgreement(!showAgreement)}
          >
            View terms of agreement
          </p>
          <Button
            id="submit"
            type="submit"
            text="Submit Acceptance"
            className="onboard-button mt-0 quote-submit"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </div>
      </div>

      <h1>QUOTE COMPLETE</h1>
      <QuoteComplete />

      <h1>QUOTE PAYMENT</h1>
      <QuotePayment />
    </div>
  );
};

export default QuoteDetails;
