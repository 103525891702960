import { useEffect } from "react";
import decode from "jwt-decode";
import useApi from "../hooks/useApi";
import { LoginUser } from "./generalService";

interface UserCredential {
  token: string;
  expiration: string;
  userName: string;
  email: string;
  refreshToken: string;
  roles: Array<string>;
  profilePic: string;
}

export const AuthService = () => {
  const loginApi = useApi(LoginUser);

  useEffect(() => {
    if (loginApi.isSuccess) {
      localStorage.setItem(
        "user-token",
        JSON.stringify(loginApi?.data?.payload)
      );
    }
  }, [loginApi.data, loginApi.isSuccess]);

  const login = ({ email, password }: { email: string; password: string }) => {
    loginApi.fetch({ email, password });
  };
  return { login, ...loginApi };
};

export const getToken = (): string | null => {
  let token = credential()?.token;
  return token ? token : null;
};

export const isAuth = (): boolean => (getToken() ? true : false);

export const credential = (): UserCredential => {
  let credObj = JSON.parse(localStorage.getItem("user-token") as string);
  return credObj as UserCredential;
};

export const getUserId = () => {
  let user = getToken();
  if (user) {
    let profile = decode(user) as any;
    return profile.UserId;
  }
  return "";
};

export const logout = () => {
  localStorage.clear();
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { logout, credential, isAuth, getToken, AuthService };
