import React, { useState } from "react";
import { useNavigate } from "react-router";

import "./Job.css";

import CalenderIcon from "../../assets/img/calendar-clock.svg";
import Heart from "../../assets/icons/heart.svg";
import HeartFiled from "../../assets/icons/heart-filled.svg";
import Info from "../../assets/img/more-info.png";
import House from "../../assets/img/house-icon.png";
import { Button } from "../customInputs/textBox";
import { RoutePath } from "../../constants/routes";

type Props = {
  name: string;
};

const Job = ({ name }: Props) => {
  const [interest, setInterest] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const navigate = useNavigate();

  const handleInterest = () => {
    setInterest(!interest);
  };
  return (
    <div
      className="quotes border text-black text-decoration-none job"
      // key={id}
    >
      <div className="">
        <img src={CalenderIcon} alt="Calender" className="calender-icon" />
        <span className="date-text">
          26th May, 2020 <span>.</span> <span>2:30pm</span>
        </span>
        <p className="quote-status" onClick={handleInterest}>
          <img
            onClick={handleInterest}
            src={interest ? HeartFiled : Heart}
            alt="Interest"
            className="me-3 heart-img"
          />
          {interest ? "Interested" : "Signify Interest"}
        </p>

        <div className="project-container">
          <h3 className="mb-0 quote-name">{name}</h3>
          <p className="project-text">Project ID: MSB/NG/SW/OGN/0015</p>
        </div>

        {showInfo && (
          <div className="moreinfo-container">
            <p className="moreinfo-header">
              3000kW load - 16 hours backup battery
            </p>
            <div className="moreinfo">
              <div className="moreinfo1">
                <h3 className="moreinfo1-header">Appliances</h3>
                <p>Ceiling Fans - 4</p>
                <p>Television - 1</p>
                <p>Light Bulbs - 15</p>
                <p>Water Pumps - 1</p>
              </div>
              <div className="moreinfo2">
                <h3 className="moreinfo2-header">Power Rating (W)</h3>
                <p>80</p>
                <p>200</p>
                <p>15</p>
                <p>1200</p>
              </div>
            </div>
          </div>
        )}
        <div className="info-section">
          <div className="info-container">
            <span className="apartment-type">
              <img src={House} alt="Apartment Type" className="house-img" />{" "}
              Residential
            </span>

            <img
              src={Info}
              alt="More Info"
              onMouseOver={() => setShowInfo(true)}
              onMouseOut={() => setShowInfo(false)}
            />
          </div>

          <Button
            id="submit"
            type="submit"
            text="View Quote"
            className="onboard-button view-quote job-quote"
            onClick={() => navigate(RoutePath.create_quote)}
          />
        </div>
      </div>
    </div>
  );
};

export default Job;
