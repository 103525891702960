import React, { useState, useEffect } from "react";

import "./Job.css";

import CalenderIcon from "../../assets/img/calendar-clock.svg";
import Notify from "../../assets/img/notification.png";
import Info from "../../assets/img/more-info.png";
import House from "../../assets/img/house-icon.png";
import { Button } from "../customInputs/textBox";
import AgreementBackdrop from "../Quote/AgreementBackdrop/AgreementBackdrop";

const BiddedJob = () => {
  const [showNotificationBar, setShowNotificationBar] = useState(true);
  const [showAgreement, setShowAgreement] = useState(false);
  const [acceptAgreement, setAcceptAgreement] = useState(false);

  useEffect(() => {
    if (acceptAgreement) setShowNotificationBar(false);
  }, [acceptAgreement]);

  return (
    <>
      {showAgreement && (
        <AgreementBackdrop
          close={() => setShowAgreement(false)}
          accept={() => setAcceptAgreement(true)}
        />
      )}
      <div
        className="quotes border text-black text-decoration-none bidded"
        // key={id}
      >
        <div className="">
          <img src={CalenderIcon} alt="Calender" className="calender-icon" />
          <span className="date-text">
            26th May, 2020 <span>.</span> <span>2:30pm</span>
          </span>
          {showNotificationBar && (
            <img src={Notify} alt="Interest" className="me-3 bidded-img" />
          )}

          <h3 className="mt-3 quote-name">Rufai Abdul-Qudus</h3>
          <div className="bidded-container">
            <span className="project-text">Project ID: MSB/NG/SW/OGN/0015</span>
            <div className="info-container">
              <span className="apartment-type bidding-type">
                <img src={House} alt="Apartment Type" className="house-img" />{" "}
                Residential
              </span>

              <img src={Info} alt="More Info" />
            </div>
          </div>

          <div className="info-section">
            <Button
              id="button"
              type="button"
              text="Accept Agreement"
              className="onboard-button view-quote job-quote"
              onClick={() => setShowAgreement(true)}
              disabled={acceptAgreement}
            />

            <Button
              id="button"
              type="button"
              text="View Quote"
              className="onboard-button view-quote job-quote"
              // onClick={onClick}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BiddedJob;
