import React, { useState } from "react";
import { Outlet } from "react-router";

import { RoutePath } from "../../constants/routes";
import MsbLogo from "../../assets/img/msblogo.png";
import NavMenu from "../../assets/img/navigation-menu-1.png";
import Sidebar from "../Sidebar/index";

import "./adminlayout.css";

const AdminLayout = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const handleSideBAr = () => {
    setShowSideBar(!showSideBar);
  };
  return (
    <div className="container-fluid p-0">
      <div className="admins-nav-container">
        <a className="logo-sidebar" href={RoutePath.home}>
          <img src={MsbLogo} alt="My Solar bid" />
        </a>
        <span></span>
        <span onClick={handleSideBAr} className="menu">
          <img src={NavMenu} alt="Menu" />
        </span>
      </div>
      <div>
        {showSideBar && <Sidebar closeSideBar={() => setShowSideBar(false)} />}
        <Outlet />
      </div>
    </div>
  );
};

export default AdminLayout;
