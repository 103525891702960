import React, { useEffect, useState, useMemo, useContext } from "react";

import Add from "../../../assets/img/add.svg";
import Minus from "../../../assets/img/minus.svg";
import SingleAppliance from "../../../components/Appliances";
import { Button } from "../../../components/customInputs/textBox";
import Appliance from "../../../models/Appliance";
import useApi from "../../../services/hooks/useApi";
import { getAllAppliances } from "../../../services/apiservice/generalService";
import { JobContext } from "../../../components/jobContext/jobContainer";

const JobAppliance = () => {
  const contextObject = useContext(JobContext);
  const [jobAppliances, setJobAppliances] = useState<Appliance[]>([]);
  const { data, fetch } = useApi<
    Array<{
      id: string;
      name: string;
      powerInWatts: number;
      iconPath: string;
      quantity: number;
    }>
  >(getAllAppliances);

  const { updateContext, pageNumber } = contextObject;
  const submitForm = (e: any) => {
    e.preventDefault();
    jobAppliances.map((item) => Reflect.deleteProperty(item, "src"));
    updateContext({
      ...contextObject,
      jobAppliances: jobAppliances,
      pageNumber: pageNumber + 1,
    });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let Appliances = useMemo(() => {
    return data?.payload?.map(
      ({
        id,
        name,
        powerInWatts,
        iconPath,
        quantity = 1,
      }: {
        id: string;
        name: string;
        powerInWatts: number;
        iconPath: string;
        quantity: number;
      }) => new Appliance(id, name, powerInWatts, iconPath, quantity)
    );
  }, [data]);

  const handleItemClick = (id: string) => {
    let valueIndex = jobAppliances.findIndex(
      (x: Appliance) => x.applianceId === id
    );
    let items = [...jobAppliances];
    valueIndex >= 0
      ? items.splice(valueIndex, 1)
      : (items = [
          ...items,
          Appliances?.find((x: Appliance) => x.applianceId === id) as Appliance,
        ]);
    // items.map((item) => Reflect.deleteProperty(item, "src"));
    setJobAppliances([...items]);
  };

  const handleQuantity = (id: string, operation: string) => {
    let tempAppliance = [...jobAppliances];
    let valueIndex = tempAppliance.find((x: Appliance) => x.applianceId === id);
    if (valueIndex) {
      if (valueIndex.quantity) {
        if (operation === "increment") {
          valueIndex.quantity += 1;
        }
        if (operation === "decrement") {
          if (valueIndex.quantity > 1) {
            valueIndex.quantity -= 1;
          }
        }
      }
    }
    setJobAppliances(tempAppliance);
  };

  return (
    <div className="job-appliance-container">
      <p className="fs-4">Select the appliances you would you like to power</p>
      <div className="appliance-container">
        <div className="appliances">
          {Appliances?.map(
            ({ src, appliance, applianceId }: Appliance, index: number) => (
              <SingleAppliance
                onClick={() => handleItemClick(applianceId)}
                name={appliance}
                url={src}
                id={applianceId}
                key={index}
                appliances={jobAppliances}
              />
            )
          )}
        </div>
        <div className="appliance-quantity">
          <div className="headers">
            <div className="left-header">
              <p className="amount mb-0">Amount</p>
              <p className="mb-0">Type of Appliance</p>
            </div>
            <p className="mb-0 right-header">Wattage</p>
          </div>
          <div className="appliance-content">
            {jobAppliances?.map((appliance) => (
              <div className="item-headers" key={appliance.applianceId}>
                <div className="left-numApp">
                  <div className="numAppliance">
                    <span
                      className="qty-add-bg pointer"
                      onClick={() =>
                        handleQuantity(appliance.applianceId, "increment")
                      }
                    >
                      <img src={Add} alt="Increase" className="increment" />
                    </span>
                    <span className="qty">
                      <p className="mb-0 qty-text">{appliance.quantity}</p>
                    </span>
                    <span
                      className="qty-minus-bg pointer"
                      onClick={() =>
                        handleQuantity(appliance.applianceId, "decrement")
                      }
                    >
                      <img src={Minus} alt="Decrement" className="decrement" />
                    </span>
                  </div>
                  <p className="mb-0 type-text">{appliance.appliance}</p>
                </div>
                <span className="watt-qty">
                  <p className="mb-0 right-header">
                    {appliance.quantity && appliance.quantity * appliance.watts}
                  </p>
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="app-btn-container">
        <Button
          id="submit"
          type="submit"
          text="Next"
          className="onboard-button appliance-btn"
          onClick={submitForm}
          disabled={jobAppliances.length === 0}
        />
      </div>
    </div>
  );
};

export default JobAppliance;
