import React, { FunctionComponent, useState, useContext } from "react";

import "./calculator.css";
import { Button, TextBox } from "../../components/customInputs/textBox";
import { CalculatorContext } from "../../components/calculatorContainer/containerContext";

const CalcEmailComponent: FunctionComponent = () => {
    const contextObject = useContext(CalculatorContext);
    const { updateContext, pageNumber } = contextObject;
    const [email, SetEmail] = useState(
        contextObject.email ? contextObject.email : ""
    );
    const submitEmail = (e: any) => {
        e.preventDefault();
        updateContext({
            ...contextObject,
            email: email,
            pageNumber: pageNumber + 1,
        });
    };

    const handleEmailChange = (e: any) => {
        SetEmail(e.target.value);
    };

    return (
        <div className="innersector-container">
            <h3 className="mt-4 salute">Hello 👋🏻</h3>
            <h1 className="question mt-2">
                I am <span className="yellow-text">Watter</span>
            </h1>
            <p className="sub-question">
                I would be recommending your solar quote
            </p>
            <form className="innersector-container" onSubmit={submitEmail}>
                <TextBox
                    placeholder="Enter your email address"
                    type="email"
                    id="email"
                    value={email}
                    className="answer-box mb-1"
                    onChange={handleEmailChange}
                />
                <Button
                    text="Let’s begin"
                    id="email-button"
                    className="calculator-button"
                    //  onClick={()=>submitEmail}
                    // onClick={() => console.log("calculator => index.tsx")}
                    disabled={email ? false : true}
                />
            </form>
        </div>
    );
};

export default CalcEmailComponent;
