import React, { useContext } from "react";
import { JobStages, RoutePath } from "../../constants/routes";
import { JobContext } from "./jobContainer";
import { Link } from "react-router-dom";
import delIcon from "../../assets/icons/delete-1.svg";
import backIcon from "../../assets/icons/back-1.svg";
import "./jobContainer.css";

const JobContainer = () => {
  const contextObj = useContext(JobContext);
  const { pageNumber, updateContext } = contextObj;
  return (
    <div className="job-body">
      <div className="top-btn">
        {pageNumber === 0 ? (
          <Link to={RoutePath.home} className="btn cancel-button">
            <img className="me-1 mt-0" src={delIcon} alt="delete Icon" />
            <span>Cancel</span>
          </Link>
        ) : (
          <span
            onClick={() =>
              updateContext({ ...contextObj, pageNumber: pageNumber - 1 })
            }
            className="btn back-button"
          >
            <img
              className="mt-0 me-1 back-image"
              src={backIcon}
              alt="delete Icon"
            />
            Back
          </span>
        )}

        {/* <div className="text-end">
          {pageNumber < JobStages.length - 1 && (
            <div className="circle-dark">
              {JobStages[pageNumber].number}/{JobStages.length - 1}
            </div>
          )}
        </div> */}
      </div>
      <div>{JobStages[pageNumber].component}</div>
    </div>
  );
};

export default JobContainer;
