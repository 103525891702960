import React, { useState, useEffect } from "react";
import backIcon from "../../../assets/icons/back-1.svg";
import ProfileImage from "../../../assets/icons/profile-placeholder.svg";
import { Link, useNavigate } from "react-router-dom";
import { Button, TextBox } from "../../../components/customInputs/textBox";
import {
  UpdateProfile,
  UploadProfilePicture,
  GetProfile,
} from "../../../services/apiservice/generalService";
import useApi from "../../../services/hooks/useApi";
import { getUserId } from "../../../services/apiservice/authService";
import { RoutePath } from "../../../constants/routes";
import { AppToast } from "../../../components/Toast/toast";
import { CustomerProfile } from "../../../models/Profile";

const EditProfile = () => {
  const updateProfile = useApi(UpdateProfile);
  const { data: profileInfo, fetch } = useApi<CustomerProfile>(GetProfile);
  const { isSuccess: picUploaded, fetch: uploadPicture } =
    useApi(UploadProfilePicture);
  const userId = getUserId();

  const [surName, setSurName] = useState<string | undefined>("");
  const [firstName, setFirstName] = useState<string | undefined>(
    profileInfo?.payload?.firstName ? profileInfo?.payload?.firstName : ""
  );
  const [otherName, setOtherName] = useState<string | undefined>(
    profileInfo?.payload?.otherName ? profileInfo?.payload?.otherName : ""
  );
  const [address, setAddress] = useState<string | undefined>(
    profileInfo?.payload?.address ? profileInfo?.payload?.address : ""
  );
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(
    profileInfo?.payload?.phoneNumber ? profileInfo?.payload?.phoneNumber : ""
  );
  const [profilePicture, setProfilePicture] = useState<File | null>();
  const [profilePic, setProfilePic] = useState<string | undefined>();
  const [errMessage, setErrMessage] = useState("");
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const navigate = useNavigate();

  const onUpdateProfile = (e: any) => {
    e.preventDefault();
    updateProfile.fetch({
      surName,
      firstName,
      otherName,
      address,
      profilePic,
      phoneNumber,
      userId,
    });
  };

  useEffect(() => {
    picUploaded &&
      AppToast({
        type: "success",
        message: "Profile Image Updated Successfully",
      });
    if (updateProfile.isSuccess) {
      navigate(RoutePath.customer_dashboard);
      AppToast({ type: "success", message: "Profile Updated Successfully" });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateProfile.isSuccess, picUploaded]);

  useEffect(() => {
    setSurName(profileInfo?.payload?.surName);
    setFirstName(profileInfo?.payload?.firstName);
    setOtherName(profileInfo?.payload?.otherName);
    setAddress(profileInfo?.payload?.address);
    setPhoneNumber(profileInfo?.payload?.phoneNumber);
    setProfilePic(profileInfo?.payload?.profilePic);
  }, [
    profileInfo?.payload?.surName,
    profileInfo?.payload?.firstName,
    profileInfo?.payload?.otherName,
    profileInfo?.payload?.address,
    profileInfo?.payload?.phoneNumber,
    profileInfo?.payload?.profilePic,
  ]);

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (e: any) => {
    hiddenFileInput?.current?.click();
    setErrMessage("");
  };

  useEffect(() => {
    if (
      profilePicture &&
      profilePicture?.size < 2097152 &&
      profilePicture?.size > 0
    ) {
      setProfilePic(URL.createObjectURL(profilePicture));
    }
  }, [profilePicture]);

  useEffect(() => {
    if (profilePicture && profilePicture?.size > 2097152) {
      setErrMessage(`You can't upload images more than 2MB`);
    } else if (profilePicture && profilePicture?.size >= 1) {
      var data = new FormData();
      data.append("file", profilePicture as Blob);
      uploadPicture(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profilePicture]);

  return (
    <div className="edit-profile-container">
      <span onClick={() => navigate(-1)} className="btn desktop">
        <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
        Back
      </span>
      <div className="edit-profile">
        <p className="edit-profile-header">
          Edit Profile<span className="header-dot">.</span>
        </p>
        {errMessage && <p className="text-danger edit-error">{errMessage}</p>}
        <form className="edit-profile-input" onSubmit={onUpdateProfile}>
          <div className="profile-img">
            <div className="custom__image-container">
              <img
                src={profilePic ? profilePic : ProfileImage}
                alt="Profile"
                className="profile-image"
                onClick={(img) => {
                  handleClick(img);
                }}
              />

              <input
                type="file"
                id="add-single-img"
                accept="image/jpeg, image/x-png"
                ref={hiddenFileInput}
                style={{ display: "none" }}
                onChange={(e) => {
                  if (e.target.files) setProfilePicture(e?.target?.files[0]);
                }}
              />
            </div>
            <Link
              to={RoutePath.change_password}
              className="text-decoration-none edit-password-btn"
            >
              <span className="edit-btn-text">Change Password</span>
            </Link>
          </div>
          <div className="profile-inputs">
            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="First Name"
                label-for="firstname"
              >
                Surname
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="Enter your surname"
                  type="text"
                  id="text"
                  className="input-width me-2"
                  required
                  onChange={(e: any) => {
                    setSurName(e.target.value);
                  }}
                  value={surName || ""}
                />
              </div>
            </div>

            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="Last Name"
                label-for="lastname"
              >
                First Name
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="Enter your first name"
                  type="text"
                  id="text"
                  className="input-width me-2"
                  required
                  onChange={(e: any) => {
                    setFirstName(e.target.value);
                  }}
                  value={firstName || ""}
                />
              </div>
            </div>

            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="Other Name"
                label-for="othername"
              >
                Other Name
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="Enter your other name"
                  type="text"
                  id="text"
                  className="input-width me-2"
                  required
                  onChange={(e: any) => {
                    setOtherName(e.target.value);
                  }}
                  value={otherName || ""}
                />
              </div>
            </div>

            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="Address"
                label-for="address"
              >
                Home Address
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="House number, Street, City, State"
                  type="text"
                  id="text"
                  className="input-width me-2"
                  required
                  onChange={(e: any) => {
                    setAddress(e.target.value);
                  }}
                  value={address || ""}
                />
              </div>
            </div>

            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="Phone Number"
                label-for="phone-number"
              >
                Phone Number
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="Enter your phone number"
                  type="text"
                  id="text"
                  className="input-width me-2"
                  required
                  max={11}
                  min={6}
                  onChange={(e: any) => {
                    if (/^\d+$/.test(e.target.value) || e.target.value === "") {
                      setPhoneNumber(e.target.value);
                    }
                  }}
                  value={phoneNumber || ""}
                />
              </div>
            </div>
            <Button
              id="submit"
              type="submit"
              text="Update"
              className="onboard-button"
              onClick={onUpdateProfile}
              disabled={
                firstName &&
                surName &&
                address &&
                phoneNumber &&
                !updateProfile.loading
                  ? false
                  : true
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditProfile;
