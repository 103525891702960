export const numberToCurrency = (currency: number, symbol = "₦") => {
  var options = {
    maximumFractionDigits: 2,
  };
  return currency
    ? `${symbol}${currency?.toLocaleString("en-gb", options)}`
    : "";
};

export const formatDateAndTime = (date: string) => {
  let formattedDate = new Date(date).toLocaleDateString("en-us", {
    weekday: "long",
    year: "numeric",
    month: "short",
    day: "numeric",
  });
  let formattedTime = new Date(date).toLocaleTimeString("en-us", {
    hour: "numeric",
    minute: "numeric",
  });
  return `${formattedDate} • ${formattedTime}`;
};
