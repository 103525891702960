import React from "react";
import { Outlet } from "react-router-dom";
import { LogoIcon2 } from "../svgIcons/logo";

import SignUpSvg from "../../assets/img/signup.svg";
import LoginSVG from "../../assets/img/login.svg";
import ForgotPasswordSVG from "../../assets/img/forgot-password.svg";

import "./onboarding.css";

interface Props {
  data: string;
}

export const OnboardingContainer = ({ data }: Props) => {
  return (
    <div className="container-fluid">
      <div className="row p-0">
        <div className="col-lg-5 col-sm-12 onboarding-container">
          <span>
            <LogoIcon2 />
          </span>
          <div className="text-img-container">
            {data === "Reset" ? (
              <h2 className="mt-1 fs-1 desktop">Hello Anthony 👋🏻</h2>
            ) : (
              ""
            )}
            {data === "Reset" ? (
              <p className="fs-5 mb-5 desktop">
                kindly enter your a new password and your account will be
                updated with this password.
              </p>
            ) : (
              ""
            )}
            <img
              src={
                data === "Signup"
                  ? SignUpSvg
                  : data === "Login"
                  ? LoginSVG
                  : ForgotPasswordSVG
              }
              alt="Sign up"
              className="onboard-image"
            />
            <h2 className="mt-1">
              {data !== "Reset"
                ? "Enjoy access to the best solar experience"
                : ""}
            </h2>
          </div>
        </div>
        <div className="col-lg-7 col-sm-12 m-0 p-0">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default OnboardingContainer;
