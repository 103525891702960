import React from "react";
import QuoteFilled from "../../assets/img/quote-accepted.svg";
import Quote from "../../assets/img/quote-unaccepted.svg";

interface QuoteProgressProps {
  name: string;
  imgUrl: string;
  active: boolean;
}
const QuoteProgress = ({ name, imgUrl, active }: QuoteProgressProps) => {
  return (
    <div>
      <img
        src={active ? QuoteFilled : Quote}
        alt={name}
        className="quote-icon"
      />

      <div className="mt-3 mx-2">
        <img src={imgUrl} alt={name} className="quote-icon" />
        <p className="mt-1 Quote-Text">{name}</p>
      </div>
    </div>
  );
};

export default QuoteProgress;
