import React, { useEffect, useState } from "react";
import CalculatorContainer from ".";
import useApi from "../../services/hooks/useApi";
import { getAllStates, getAllAppliances} from '../../services/apiservice/generalService';
export const CalculatorContext = React.createContext();

 const CalculatorContextComponent = ({children}) => {
  const [contextObj, setContextObj] = useState({ pageNumber: 0});
  const AllStates = useApi(getAllStates);
  const AllAppliances = useApi(getAllAppliances);

  const updateContext = (object) => {
    setContextObj({ ...contextObj, ...object });
  };

  useEffect(()=>{
     AllStates.fetch();
    AllAppliances.fetch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(()=>{
   updateContext({ states : { data: AllStates.data, loading: AllStates.loading, error: AllStates.error}, 
                appliances: {data: AllAppliances.data, loading: AllAppliances.loading, error: AllAppliances.error }})
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[AllStates.data, AllStates.loading, AllStates.error, AllAppliances.data, AllAppliances.loading, AllAppliances.error])

  useEffect(()=>{
    let context = localStorage.getItem('context');
    if(context) setContextObj(JSON.parse(context))
  },[])



  useEffect(()=>{
      localStorage.setItem('context', JSON.stringify(contextObj))
  },[contextObj])
  return (
    <div>
      <CalculatorContext.Provider value={{ ...contextObj, updateContext }}>
        <CalculatorContainer>
            {children}
        </CalculatorContainer>
      </CalculatorContext.Provider>
    </div>
  );
};

export default React.memo(CalculatorContextComponent);
