export default class State {
  value: string;
  label: string;
  id?: string;
  constructor(state: string) {
    this.value = state;
    this.label = state;
    this.id = state;
  }
}
