import React, { useState } from "react";

import CalenderIcon from "../../assets/img/calendar-clock.svg";
import QuoteFilled from "../../assets/img/quote-accepted.svg";
import Quote from "../../assets/img/quote-unaccepted.svg";
import Arrow from "../../assets/img/Arrow.svg";
import { Link } from "react-router-dom";
import { RoutePath } from "../../constants/routes";
import { formatDateAndTime } from "../../utility";

interface Props {
  name: string;
  isActive: any;
  id: string;
  createdDate: string;
  num: number;
  projectID: string;
}

function Quotes({ name, createdDate, id, num, projectID }: Props) {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(true);
  };

  localStorage.setItem("jobId", JSON.stringify(id));

  return (
    <Link
      to={{
        pathname: RoutePath.quote_details,
      }}
      className={`${
        num === 2 || num === 1 ? "px-quotes" : "pc-quote"
      } quotes border text-black text-decoration-none  ${
        clicked ? "border-warning" : ""
      }`}
      onClick={handleClick}
      key={id}
    >
      <div className="">
        <img src={CalenderIcon} alt="Calender" className="calender" />
        <span className="date-text">{formatDateAndTime(createdDate)}</span>
        <p className="quote-status">Posted</p>

        <div className="project-container">
          <h3 className="mb-0 quote-name">{name}</h3>
          <p className="project-text">Project ID: {projectID}</p>
        </div>

        <div>
          <img src={QuoteFilled} alt="Quote Accepted" className="quote-icon" />{" "}
          <span className="quote-text">Quote received</span>{" "}
          <img src={Arrow} alt="Arrow" className="arrow" />{" "}
          <img src={Quote} alt="Quote Unaccepted" className="quote-icon" />{" "}
          <span className="quote-text">Quote unaccepted</span>
        </div>
      </div>
    </Link>
  );
}

export default Quotes;
