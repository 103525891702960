import React, { useEffect, useState } from "react";

import backIcon from "../../../assets/icons/back-1.svg";
import { Button, TextBox } from "../../../components/customInputs/textBox";
import useApi from "../../../services/hooks/useApi";
import { ChangeUserPassword } from "../../../services/apiservice/generalService";
import { useNavigate } from "react-router";
import { AppToast } from "../../../components/Toast/toast";
import { RoutePath } from "../../../constants/routes";

interface checkPasswordModel {
  uppercase: string;
  lowercase: string;
  digits: string;
  passwordLongEnough: string;
  passwordValid: boolean;
  upperCaseStyle: string;
  lowerCaseStyle: string;
  digitsStyle: string;
  passwordLongEnoughStyle: string;
}

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checkPassword, setCheckPassword] = useState<checkPasswordModel>({
    uppercase: "",
    lowercase: "",
    digits: "",
    passwordLongEnough: "",
    passwordValid: false,
    upperCaseStyle: "",
    lowerCaseStyle: "",
    digitsStyle: "",
    passwordLongEnoughStyle: "",
  });
  const {isSuccess, loading, fetch: updatePassword, data} = useApi(ChangeUserPassword);
  const navigate = useNavigate();

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    updatePassword({ oldPassword, password, confirmPassword });
  };

  const handleOldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOldPassword(e.target.value);
  };

  const handleNewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    checkPwd(e.target.value);
  };

  const handleConfirmChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(e.target.value);
  };

  const checkPwd = (password: string) => {
    var digitEx = new RegExp(/\d/);
    var lowerEx = new RegExp(/[a-z]/);
    var upperCase = new RegExp(/[A-Z]/);
    let condition = true;
    let upperCaseStyle = "valid";
    let lowerCaseStyle = "valid";
    let digitsStyle = "valid";
    let passwordLongEnoughStyle = "valid";
    let uppercase = "Uppercase letter";
    let lowercase = "Lowercase letter";
    let digits = "Contains a numbers";
    let passwordLongEnough = "More than 8 characters";
    if (password.length < 8) {
      passwordLongEnoughStyle = "invalid";
      condition = false;
    }
    if (!upperCase.test(password)) {
      upperCaseStyle = "invalid";
      condition = false;
    }
    if (!lowerEx.test(password)) {
      lowerCaseStyle = "invalid";
      condition = false;
    }
    if (!digitEx.test(password)) {
      digitsStyle = "invalid";
      condition = false;
    }
    setCheckPassword({
      uppercase,
      upperCaseStyle,
      lowercase,
      lowerCaseStyle,
      digits,
      digitsStyle,
      passwordLongEnough,
      passwordLongEnoughStyle,
      passwordValid: condition,
    });
  };

  useEffect(()=>{
    if(isSuccess){
      AppToast({ type: 'success', message : data?.description || "Password updated"});
      navigate(RoutePath.edit_profile);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[isSuccess])
  return (
    <div className="edit-profile-container">
      <span onClick={() => navigate(-1)} className="btn desktop">
        <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
        Back
      </span>
      <div className="edit-profile">
        <p className="edit-profile-header">
          Change Password<span className="header-dot">.</span>
        </p>
        <form className="edit-profile-input">
          {isSuccess && (
            <span className="change-password-info">
              {data?.description}
            </span>
          )}
          <div className="profile-inputs">
            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="Old Password"
                label-for="old-Password"
              >
                Old Password
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="Enter your old password"
                  type="password"
                  id="oldpassword"
                  className="input-width me-2"
                  required
                  onChange={handleOldChange}
                  value={oldPassword}
                />
              </div>
            </div>

            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="New Password"
                label-for="new-password"
              >
                New Password
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="Enter your new password"
                  type="password"
                  id="password"
                  className="input-width me-2"
                  required
                  onChange={handleNewChange}
                  value={password}
                />
              </div>
            </div>

            <div className="joblist-input mb-3">
              <label
                className="input-label"
                aria-label="Other Name"
                label-for="othername"
              >
                Re-enter new password
              </label>
              <div className="d-flex">
                <TextBox
                  placeholder="Re-enter the new password"
                  type="password"
                  id="confirmpassword"
                  className="input-width me-2"
                  required
                  onChange={handleConfirmChange}
                  value={confirmPassword}
                />
              </div>

              <div className="mt-2 mb-3">
                <span className={checkPassword.upperCaseStyle}>
                  {checkPassword.uppercase}
                </span>
                <span className={checkPassword.lowerCaseStyle}>
                  {checkPassword.lowercase}
                </span>
                <span className={checkPassword.digitsStyle}>
                  {checkPassword.digits}
                </span>
                <span className={checkPassword.passwordLongEnoughStyle}>
                  {checkPassword.passwordLongEnough}
                </span>
                {confirmPassword && password !== confirmPassword && <span className="invalid">
                   Password and confirm passwords do not match
                </span>}
              </div>
            </div>

            <Button
              id="submit"
              type="submit"
              text="Change Password"
              className="onboard-button"
              onClick={onSubmit}
              disabled={
                !checkPassword.passwordValid || loading ||
                password !== confirmPassword
              }
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
