import React, { useState, useEffect } from "react";
import { Button, TextBox } from "../../../components/customInputs/textBox";
import "../../customer/customer.css";
import backIcon from "../../../assets/icons/back-1.svg";
import EmailVerificationModal from "../../../components/EmailVerificationModal";

interface checkPasswordModel {
  uppercase: string;
  lowercase: string;
  digits: string;
  passwordLongEnough: string;
  passwordValid: boolean;
  upperCaseStyle: string;
  lowerCaseStyle: string;
  digitsStyle: string;
  passwordLongEnoughStyle: string;
}

interface Props {
  reset: any;
}

const ResetPassword = ({ reset }: Props) => {
  const [password, setPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<checkPasswordModel>({
    uppercase: "",
    lowercase: "",
    digits: "",
    passwordLongEnough: "",
    passwordValid: false,
    upperCaseStyle: "",
    lowerCaseStyle: "",
    digitsStyle: "",
    passwordLongEnoughStyle: "",
  });
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setShowModal(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    checkPwd(e.target.value);
  };

  useEffect(() => {
    reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkPwd = (password: string) => {
    var digitEx = new RegExp(/\d/);
    var lowerEx = new RegExp(/[a-z]/);
    var upperCase = new RegExp(/[A-Z]/);
    let condition = true;
    let upperCaseStyle = "valid validaton-text";
    let lowerCaseStyle = "valid validaton-text";
    let digitsStyle = "valid validaton-text";
    let passwordLongEnoughStyle = "valid validaton-text";
    let uppercase = "Uppercase letter";
    let lowercase = "Lowercase letter";
    let digits = "Contains a numbers";
    let passwordLongEnough = "More than 8 characters";
    if (password.length < 8) {
      passwordLongEnoughStyle = "invalid";
      condition = false;
    }
    if (!upperCase.test(password)) {
      upperCaseStyle = "invalid";
      condition = false;
    }
    if (!lowerEx.test(password)) {
      lowerCaseStyle = "invalid";
      condition = false;
    }
    if (!digitEx.test(password)) {
      digitsStyle = "invalid";
      condition = false;
    }
    setCheckPassword({
      uppercase,
      upperCaseStyle,
      lowercase,
      lowerCaseStyle,
      digits,
      digitsStyle,
      passwordLongEnough,
      passwordLongEnoughStyle,
      passwordValid: condition,
    });
  };
  return (
    <div className="signup-container">
      {showModal && (
        <EmailVerificationModal
          cancel={() => setShowModal(!showModal)}
          title={"Reset Successful."}
          desc={
            "You have successfully reset your password! You can easily login now."
          }
          btn={"Return to Login"}
        />
      )}
      {/* Help me sort the error here in the Modal Component, The close button is not working. */}
      <span onClick={() => {}} className="btn desktop">
        <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
        Back
      </span>
      <div className="form-container">
        <h1 className="form-header fs-1 mb-0">
          Reset Password
          <span className="header-dot">.</span>
        </h1>
        <form className="onboard-form">
          <p className="text-danger mb-0 input-label"></p>

          <div className="group-input mb-2">
            <label
              className="input-label"
              label-for="password"
              aria-label="Create passwords"
            >
              Create a Password
            </label>
            <TextBox
              placeholder="Create a Password"
              type="Password"
              id="password"
              required
              onChange={handleChange}
              value={password}
            />
          </div>

          <div className="mt-2 mb-3">
            <span className={checkPassword.upperCaseStyle}>
              {checkPassword.uppercase}
            </span>
            <span className={checkPassword.lowerCaseStyle}>
              {checkPassword.lowercase}
            </span>
            <span className={checkPassword.digitsStyle}>
              {checkPassword.digits}
            </span>
            <span className={checkPassword.passwordLongEnoughStyle}>
              {checkPassword.passwordLongEnough}
            </span>
          </div>
          <Button
            id="submit"
            type="submit"
            text="Reset Password"
            className="onboard-button"
            onClick={handleClick}
            disabled={!checkPassword.passwordValid}
          />
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
