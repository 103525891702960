import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../constants/routes";
import { Button } from "../../../components/customInputs/textBox";
import Umbrella from "../../../assets/img/modal.svg";

import "../../../components/EmailVerificationModal/Modal.css";

interface Props {
  signupData: any;
}

const EmailVerfication = ({ signupData }: Props) => {
  const navigate = useNavigate();

  useEffect(() => {
    signupData();
  });

  return (
    <div className="backdrop">
      <div className="modal-container rounded verification-container">
        <div>
          <img src={Umbrella} alt="Umbrella" className="u-img mt-5" />
          <h3 className="mt-5 mb-5">Email Verification Complete</h3>
          <p className="mt-3 user-text"></p>

          <Button
            id="modal-btn"
            text="Proceed to Login"
            type="button"
            className="modal-btn mt-5 verification-btn"
            onClick={() => navigate(RoutePath.customer_login)}
          />
        </div>
      </div>
    </div>
  );
};

export default EmailVerfication;
