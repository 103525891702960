import React, { FunctionComponent, useState, useContext } from "react";
import { CalculatorContext } from "../../components/calculatorContainer/containerContext";

import "./calculator.css";
import {
    Button,
    SuggestionButtons,
    TextBox,
} from "../../components/customInputs/textBox";

export const CalcElectricityComponent: FunctionComponent = () => {
    const contextObject = useContext(CalculatorContext);
    const { updateContext, pageNumber } = contextObject;
    const [electricityHours, setElectricityHours] = useState<number>(
        contextObject?.electricityHours || 0
    );

    const submitElectricityHours = (e: any) => {
        e.preventDefault();
        updateContext({
            ...contextObject,
            electricityHours,
            pageNumber: pageNumber + 1,
        });
    };

    const HourSuggestions = [
        { label: "4 hours", value: 4 },
        { label: "8 hours", value: 8 },
        { label: "10 hours", value: 10 },
        { label: "14 hours", value: 14 },
        { label: "18 hours", value: 18 },
        { label: "20 hours", value: 20 },
        { label: "24 hours", value: 24 },
    ];

    const handleElectricityHours = (e: any) => {
        setElectricityHours(parseInt(e.target.value));
    };

    const handleSuggestionClick = (hours: number) => {
        setElectricityHours(hours);
    };

    return (
        <div className="innersector-container">
            <h1 className="question font-48 mt-2">
                How much electricity do you have in a day?
            </h1>
            <p className="sub-question">
                please enter the number of hours you have electricity daily.
            </p>
            <form
                className="innersector-container"
                onSubmit={submitElectricityHours}
            >
                <TextBox
                    placeholder="Number of hours"
                    type="number"
                    id="elec-hours"
                    value={electricityHours}
                    className="answer-box mb-1"
                    onChange={handleElectricityHours}
                    max={24}
                    min={1}
                    unit="Hours"
                />
                <Button
                    text="Next"
                    id="elec-button"
                    className="calculator-button"
                    disabled={electricityHours ? false : true}
                    // onClick={() => console.log("calcElec.tsx")}
                />
                <div className="suggest-box">
                    {HourSuggestions.map(({ label, value }, index) => {
                        return (
                            <SuggestionButtons
                                key={`${label}-${index}`}
                                type="button"
                                onClick={() => handleSuggestionClick(value)}
                                id={`${label}-${index}`}
                                text={label}
                            />
                        );
                    })}
                </div>
            </form>
        </div>
    );
};
export default CalcElectricityComponent;
