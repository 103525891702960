import React, { FunctionComponent, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import HomeComponent from "./views/home";
// import CalcEmailComponent from './views/calculator';
import CalculatorContextComponent from "./components/calculatorContainer/containerContext";
import CalculatorContainer from "./components/calculatorContainer";
import JobContextComponent from "./components/jobContext/jobContainer";
import JobContainer from "./components/jobContext";
import { RoutePath } from "./constants/routes";
import OnboardingContainer from "./components/onboardingContainer";
import Signup from "./views/customer/onboarding/signup";
import Login from "./views/customer/onboarding/login";
import AuthLayout from "./components/AuthLayout";
import CustomerDashboard from "./views/customer/dashboard/dashboard";
import QuoteDetails from "./views/customer/dashboard/quoteDetails";
import ForgotPassword from "./views/customer/onboarding/forgotPassword";
import ResetPassword from "./views/customer/onboarding/resetPassword";
import EmailVerfication from "./views/customer/onboarding/emailVerfication";
import EditProfile from "./views/customer/dashboard/editProfile";
import ChangePassword from "./views/customer/dashboard/changePassword";
import AdminDashboard from "./views/admin/adminDashboard";
import AdminLayout from "./components/AdminLayout";
import CreateQuoteContainerContext from "./components/AdminJobs/CreateQuoteContext/CreateQuoteContainer.jsx";
import CreateQuoteContainer from "./components/AdminJobs/CreateQuoteContext";

const App: FunctionComponent = () => {
  const [data, setData] = useState<string>("");
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route path={RoutePath.home} element={<HomeComponent />} />
          <Route
            path={RoutePath.calculator}
            element={
              <CalculatorContextComponent children={CalculatorContainer} />
            }
          />
          <Route element={<OnboardingContainer data={data} />}>
            <Route
              path={RoutePath.customer_signup}
              element={<Signup signupData={() => setData("Signup")} />}
            />
            <Route
              path={RoutePath.customer_login}
              element={<Login loginData={() => setData("Login")} />}
            />
            <Route
              path={RoutePath.forgot_password}
              element={
                <ForgotPassword forgotPassword={() => setData("Forgot")} />
              }
            />
            <Route
              path={RoutePath.reset_password}
              element={<ResetPassword reset={() => setData("Reset")} />}
            />
            <Route
              path={RoutePath.customer_verification}
              element={
                <EmailVerfication signupData={() => setData("Signup")} />
              }
            />
          </Route>
          <Route element={<AuthLayout />}>
            <Route
              element={<CustomerDashboard />}
              path={RoutePath.customer_dashboard}
            />
            <Route element={<QuoteDetails />} path={RoutePath.quote_details} />
            <Route element={<EditProfile />} path={RoutePath.edit_profile} />
            <Route
              element={<ChangePassword />}
              path={RoutePath.change_password}
            />
            <Route
              path={RoutePath.create_job}
              element={<JobContextComponent children={JobContainer} />}
            />
          </Route>
          <Route element={<AdminLayout />}>
            <Route
              path={RoutePath.admin_dashboard}
              element={<AdminDashboard />}
            />
            <Route
              path={RoutePath.create_quote}
              element={
                <CreateQuoteContainerContext children={CreateQuoteContainer} />
              }
            />
          </Route>
          <Route path="*" element={<HomeComponent />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
