import ConfirmQuote from "../views/admin/confirmQuote";
import CreateQuote1 from "../views/admin/createQuote1";
import CreateQuote2 from "../views/admin/createQuote2";
import CreateQuote3 from "../views/admin/createQuote3";
import CreateQuote4 from "../views/admin/createQuote4";
import CalcEmailComponent from "../views/calculator";
import CalcApplianceComponent from "../views/calculator/calcAppliance";
import CalcBackupHourComponent from "../views/calculator/calcBackupHour";
import CalcElectricityComponent from "../views/calculator/calcElec";
import CalcExpenseComponent from "../views/calculator/calcExpense";
import CalcResultComponent from "../views/calculator/calcResultPage";
import CalcStateComponent from "../views/calculator/calcState";
import JobAppliance from "../views/customer/dashboard/jobAppliance";
import JobList from "../views/customer/dashboard/jobList";
import JobVerification from "../views/customer/dashboard/jobVerification";

export const RoutePath = {
  calculator: "/calculator",
  home: "/",
  customer_signup: "/customer/signup",
  customer_login: "/customer/login",
  create_job: "/customer/create-quote",
  customer_dashboard: "/customer/customer-dashboard",
  quote_details: "/customer/quote-details",
  edit_profile: "/customer/edit-profile",
  customer_verification: "/user/verify-email",
  forgot_password: "/customer/forgot-password",
  reset_password: "/customer/reset-password",
  change_password: "/customer/change-password",
  admin_dashboard: "/admin/admin-dashboard",
  create_quote: "/admin/create-quote",
  create_quote1: "/admin/create-quote",
  create_quote2: "/admin/create-quote",
  create_quote3: "/admin/create-quote",
  create_quote4: "/admin/create-quote",
  create_quote5: "/admin/create-quote",
};

export const JobStages = [
  { number: 1, component: <JobList /> },
  { number: 2, component: <JobAppliance /> },
  { number: 3, component: <JobVerification /> },
];

export const CreateQuoteStages = [
  { number: 1, component: <CreateQuote1 /> },
  { number: 2, component: <CreateQuote2 /> },
  { number: 3, component: <CreateQuote3 /> },
  { number: 4, component: <CreateQuote4 /> },
  { number: 5, component: <ConfirmQuote /> },
];

export const CalculatorStages = [
  { number: 1, component: <CalcEmailComponent /> },
  { number: 2, component: <CalcStateComponent /> },
  { number: 3, component: <CalcElectricityComponent /> },
  { number: 4, component: <CalcExpenseComponent /> },
  { number: 5, component: <CalcApplianceComponent /> },
  { number: 6, component: <CalcBackupHourComponent /> },
  { number: 7, component: <CalcResultComponent /> },
];
