import React, {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Slider from "@mui/material/Slider";
import NairaIcon from "../../assets/icons/naira.svg";
import HourGlass from "../../assets/icons/hourglass.svg";
import { CalculatorContext } from "../../components/calculatorContainer/containerContext";
import { Button } from "../../components/customInputs/textBox";
import useApi from "../../services/hooks/useApi";
import { estimateCost } from "../../services/apiservice/generalService";
import CalculatorModel, { CalculatorResultModel } from "../../models/calculator";
import Skeleton from "@mui/material/Skeleton";
import { numberToCurrency } from "../../utility";
import useWindowDimensions from "../../utility/hooks";
import InstallationEstimate from "../../models/InstallationEstimate";
import { RoutePath } from "../../constants/routes";

export const CalcResultComponent: FunctionComponent = () => {
  const contextObject = useContext(CalculatorContext);
  const { width } = useWindowDimensions();
  const navigate = useNavigate();
  const {
    email,
    selectedAppliances,
    electricityHours,
    electricityCost,
    state,
    updateContext,
  } = contextObject;
  const [backupHours, setBackUpHours] = useState<number>(
    contextObject?.backupHours || 4
  );
  let estimate = useApi<CalculatorResultModel>(estimateCost);
  const [estimateResults, setEstimateResults] = useState<Array<InstallationEstimate>>();
  const [actualEstimate, setActualEstimate ] = useState<InstallationEstimate>();
  useEffect(() => {
    updateContext({ ...contextObject, backupHours });
    estimate.fetch(
      new CalculatorModel(
        email,
        electricityHours,
        backupHours,
        state.label,
        electricityCost,
        selectedAppliances
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setEstimateResults(estimate?.data?.payload?.data as InstallationEstimate[]);
  }, [estimate?.data]);

  useEffect(()=>{
    let value = estimateResults?.find((x: InstallationEstimate) => x.backupHours === backupHours);
    setActualEstimate(value);
  },[estimateResults, backupHours])
  const marks = [
    {
      value: 4,
      label: "4",
    },
    {
      value: 8,
      label: "8",
    },
    {
      value: 10,
      label: "10",
    },
    {
      value: 14,
      label: "14",
    },
    {
      value: 18,
      label: "18",
    },
    {
      value: 20,
      label: "20",
    },
    {
      value: 24,
      label: "24",
    },
  ];

  const handleChange = (event: Event, newValue: number | number[]) => {
    setBackUpHours(newValue as number);
  };

  const handleSignup = (e: any) => {
    e.preventDefault();
    if (estimate?.data?.payload?.isExistingUser) {
      navigate(RoutePath.customer_dashboard);
    } else {
      navigate(RoutePath.customer_signup);
    }
  };
  return (
    <div className="innersector-container mt-5">
      <div className="row calculator-container">
        <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
          <p className="result-header">Current Electricity Usage</p>
          <div className="result-box">
            <div className="red-strip"></div>
            {estimate.loading ? (
              <Skeleton
                animation="wave"
                sx={{ height: width <= 500 ? 100 : 130 }}
                variant="rectangular"
              />
            ) : (
              <div className="result-boxinner">
                <div className="m-2">
                  <img className="mr-2" src={NairaIcon} alt="Naira" />
                  yearly expense -{" "}
                  {actualEstimate?.annualExpenses
                    ? `${numberToCurrency(actualEstimate.annualExpenses)}/year.`
                    : "N/A."}
                </div>
                <div className="m-2">
                  <img className="mr-2" src={HourGlass} alt="Time" />
                  daily usage -{" "}
                  {actualEstimate?.dailyPeakElecHours
                    ? `Less than ${actualEstimate.dailyPeakElecHours} hours/day`
                    : "N/A"}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 mb-5">
          <p className="result-header">With Solar</p>
          <div className="result-box">
            <div className="red-strip green"></div>
            {estimate.loading ? (
              <Skeleton
                animation="wave"
                sx={{ height: width <= 500 ? 100 : 130 }}
                variant="rectangular"
              />
            ) : (
              <div className="result-boxinner">
                <div className="m-2">
                  <img className="mr-2" src={NairaIcon} alt="Naira" />
                  you save -{" "}
                  {actualEstimate?.annualSavedCost
                    ? `${numberToCurrency(actualEstimate.annualSavedCost)}/year`
                    : "N/A"}
                </div>
                <div className="m-2">
                  <img className="mr-2" src={HourGlass} alt="Time" />
                  daily usage -{" "}
                  {actualEstimate?.solarDailyPeakElecHours
                    ? actualEstimate.solarDailyPeakElecHours >= 24
                      ? "All Day"
                      : `At least ${actualEstimate.solarDailyPeakElecHours} hours/Day`
                    : "N/A"}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="col-12 text-center estimate-box">
          <p> Your Estimate is</p>
          <h2 className="mb-5 text-center">
            {estimate.loading ? (
              <Skeleton animation="wave" height={40} variant="text" />
            ) : (
              actualEstimate?.estimate &&
              numberToCurrency(actualEstimate.estimate)
            )}
          </h2>
          <span>
            <label>Backup hours</label>
            <Slider
              aria-label="Custom marks"
              defaultValue={backupHours}
              max={24}
              min={4}
              onChange={handleChange}
              // getAriaValueText={valuetext}
              step={1}
              valueLabelDisplay="auto"
              marks={marks}
              sx={{
                height: 16,
                padding: 0,
                backgroundColor: "#FAFAFA",
                "& .MuiSlider-rail": {
                  backgroundColor: "#FAFAFA",
                },
                "& .MuiSlider-track": {
                  backgroundColor: "#BFBFBF",
                  border: "none",
                },
                "& .MuiSlider-thumb": {
                  color: "transparent",
                },
                "& .MuiSlider-markLabel": {
                  width: 24,
                  height: 24,
                  fontSize: 14,
                  borderWidth: 1,
                  borderStyle: "solid",
                  borderColor: "#BFBFBF",
                  borderRadius: 12,
                },
                "& .MuiSlider-markLabelActive": {
                  borderColor: "#1B1B1B",
                },
              }}
            />
          </span>
          <Button
            text={estimate?.data?.payload?.isExistingUser ? "Log in and Create Job" : "Signup and Create Job"}
            type="button"
            id="state-button"
            className="calculator-button auto-width mt-5 mb-5"
            onClick={handleSignup}
          />
        </div>
      </div>
    </div>
  );
};

export default CalcResultComponent;
