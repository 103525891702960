import React, { FunctionComponent, useEffect, useState } from "react";
import "./customInputs.css";

interface ITextboxProps {
  type: string;
  placeholder?: string;
  required?: boolean;
  value: string | number;
  id: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  className?: string;
  unit?: string;
  currency?: "NGN" | string;
  max?: number;
  min?: number;
  isCurrency?: boolean;
  onBlur?: React.FocusEvent<HTMLInputElement>;
  pattern?: string;
  ref?: string | null;
  disabled?: boolean;
}

interface IButtonProps {
  type?: "submit" | "button";
  text: string;
  id: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
  className?: string;
  disabled?: boolean;
}

export const TextBox: FunctionComponent<ITextboxProps> = ({
  type = "text",
  placeholder,
  required = true,
  value,
  id,
  onChange,
  className,
  unit,
  currency = "NGN",
  pattern,
  max = 50,
  min = 1,
  isCurrency = false,
  onBlur,
  ref,
  disabled,
}) => {
  const [currencyValue, setCurrencyValue] = useState(value);

  const convertToCurrency = (s: string) => {
    var options = {
      maximumFractionDigits: 2,
    };
    setCurrencyValue(
      s ? localStringToNumber(s).toLocaleString("en-gb", options) : ""
    );
  };

  useEffect(() => {
    convertToCurrency(String(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setCurrencyValue(value ? localStringToNumber(value) : "");
  };

  function onBlurAction(e: React.ChangeEvent<HTMLInputElement>) {
    var value = e.target.value;
    convertToCurrency(value);
  }

  function localStringToNumber(s: string) {
    return Number(String(s).replace(/[^0-9.-]+/g, ""));
  }
  return (
    <div className={className}>
      <input
        className={`form-control form-control-lg calc-input`}
        value={isCurrency ? currencyValue : value}
        id={id}
        placeholder={placeholder}
        type={type}
        required={required}
        onChange={onChange}
        maxLength={max}
        minLength={min}
        onFocus={isCurrency ? onFocus : () => {}}
        // onFocus={onFocus}
        onBlur={isCurrency ? onBlurAction : () => {}}
        pattern={pattern}
        disabled={disabled}
      />
      {unit && <span className="unit-class">{unit}</span>}
    </div>
  );
};

export const Button: FunctionComponent<IButtonProps> = ({
  type = "submit",
  text,
  id,
  onClick,
  className = "",
  disabled = false,
}) => {
  return (
    <button
      type={type}
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={`btn btn-lg ${className}`}
    >
      <span className="btn-text">{text}</span>
    </button>
  );
};

export const SuggestionButtons: FunctionComponent<IButtonProps> = ({
  type = "button",
  text,
  id,
  onClick,
  className = "",
  disabled = false,
}) => {
  return (
    <button
      type={type}
      id={id}
      onClick={onClick}
      disabled={disabled}
      className={`btn suggest-button ${className}`}
    >
      {text}
    </button>
  );
};
