import React, { useState, useEffect } from "react";

import "../../customer/customer.css";
import backIcon from "../../../assets/icons/back-1.svg";
import { Button, TextBox } from "../../../components/customInputs/textBox";
import EmailVerificationModal from "../../../components/EmailVerificationModal";
import useApi from "../../../services/hooks/useApi";
import { createUser } from "../../../services/apiservice/onboardingService";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../constants/routes";

interface checkPasswordModel {
  uppercase: string;
  lowercase: string;
  digits: string;
  passwordLongEnough: string;
  passwordValid: boolean;
  upperCaseStyle: string;
  lowerCaseStyle: string;
  digitsStyle: string;
  passwordLongEnoughStyle: string;
}
interface Props {
  signupData: any;
}

export const Signup = ({ signupData }: Props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [checkPassword, setCheckPassword] = useState<checkPasswordModel>({
    uppercase: "",
    lowercase: "",
    digits: "",
    passwordLongEnough: "",
    passwordValid: false,
    upperCaseStyle: "",
    lowerCaseStyle: "",
    digitsStyle: "",
    passwordLongEnoughStyle: "",
  });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isValid, setIsValid] = useState(false);
  const { fetch, loading, error, data } = useApi(createUser);
  const navigate = useNavigate();

  useEffect(() => {
    if (data?.code === 1) {
      setShowModal(true);
    }
  }, [data?.code]);

  const emailValidation = (email: string) => {
    const regex = new RegExp(
      /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{3,})$/i
    );
    return !(!email || regex.test(email) === false);
  };

  const onHandleChange = (email: string) => {
    const isEmailValid = emailValidation(email);
    setIsValid(isEmailValid);
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // fetch({ email, password });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    checkPwd(e.target.value);
  };

  useEffect(() => {
    signupData();
  });

  const checkPwd = (password: string) => {
    var digitEx = new RegExp(/\d/);
    var lowerEx = new RegExp(/[a-z]/);
    var upperCase = new RegExp(/[A-Z]/);
    let condition = true;
    let upperCaseStyle = "valid";
    let lowerCaseStyle = "valid";
    let digitsStyle = "valid";
    let passwordLongEnoughStyle = "valid";
    let uppercase = "Uppercase letter";
    let lowercase = "Lowercase letter";
    let digits = "Contains a numbers";
    let passwordLongEnough = "More than 8 characters";
    if (password.length < 8) {
      passwordLongEnoughStyle = "invalid";
      condition = false;
    }
    if (!upperCase.test(password)) {
      upperCaseStyle = "invalid";
      condition = false;
    }
    if (!lowerEx.test(password)) {
      lowerCaseStyle = "invalid";
      condition = false;
    }
    if (!digitEx.test(password)) {
      digitsStyle = "invalid";
      condition = false;
    }
    setCheckPassword({
      uppercase,
      upperCaseStyle,
      lowercase,
      lowerCaseStyle,
      digits,
      digitsStyle,
      passwordLongEnough,
      passwordLongEnoughStyle,
      passwordValid: condition,
    });
  };
  return (
    <div className="signup-container">
      {showModal && (
        <EmailVerificationModal
          user={email}
          cancel={() => setShowModal(!showModal)}
          title={"Verify your email"}
          btnText={"Resend Verification link"}
        />
      )}
      {/* Help me sort the error here in the Modal Component, The close button is not working. */}
      <span onClick={() => navigate(-1)} className="btn desktop">
        <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
        Back
      </span>
      <div className="form-container">
        <h1 className="form-header">
          SignUp
          <span className="header-dot">.</span>
        </h1>
        <Link
          to={RoutePath.customer_login}
          className="text-black text-decoration-none"
        >
          <h3 className="form-sub">Login</h3>
        </Link>
        <form className="onboard-form">
          {data?.code !== 1 && (
            <p className="text-danger mb-0 input-label">{error}</p>
          )}
          <div className="group-input">
            <label
              className="input-label"
              aria-label="Email Address"
              label-for="email"
            >
              Email address
            </label>
            <TextBox
              placeholder="Enter your email address"
              type="email"
              id="email"
              required
              onChange={(e: any) => {
                setEmail(e.target.value);
                onHandleChange(e.target.value);
              }}
              value={email}
            />
          </div>
          <div className="group-input mb-2">
            <label
              className="input-label"
              label-for="password"
              aria-label="Create passwords"
            >
              Create Password
            </label>
            <TextBox
              placeholder="Create a Password"
              type="Password"
              id="password"
              required
              onChange={handleChange}
              value={password}
            />
          </div>

          <div className="mt-2 mb-3">
            <span className={checkPassword.upperCaseStyle}>
              {checkPassword.uppercase}
            </span>
            <span className={checkPassword.lowerCaseStyle}>
              {checkPassword.lowercase}
            </span>
            <span className={checkPassword.digitsStyle}>
              {checkPassword.digits}
            </span>
            <span className={checkPassword.passwordLongEnoughStyle}>
              {checkPassword.passwordLongEnough}
            </span>
          </div>
          <Button
            id="submit"
            type="submit"
            text="SignUp"
            className="onboard-button"
            onClick={handleClick}
            disabled={!checkPassword.passwordValid || loading || !isValid}
          />
          <div className="text-center terms-text mt-2">
            <span>
              By signing-up in you are accepting our{" "}
              <span className="yellow-text">Terms & Conditions</span> and{" "}
              <span className="yellow-text">Privacy of Use.</span>
            </span>
          </div>
        </form>
        <div className="base-question">
          <p>
            Already have an account?{" "}
            <Link
              to={RoutePath.customer_login}
              className="text-black text-decoration-none"
            >
              <span>Login</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Signup;
