export default class Appliance {
  applianceId: string;
  appliance: string;
  watts: number;
  src: string;
  quantity?: number;

  constructor(
    applianceId: string,
    appliance: string,
    watts: number,
    src: string,
    quantity?: number
  ) {
    this.applianceId = applianceId;
    this.appliance = appliance;
    this.watts = watts;
    this.src = src;
    this.quantity = quantity;
  }
}
