import React from "react";
import Cancel from "../../../assets/icons/delete-1.svg";
import Congrat from "../../../assets/icons/taxi.png";

import "./styles.css";
import { Button } from "../../customInputs/textBox";

type QuoteReceivedModalProp = {
  user: string;
  desc?: string;
  cancel: any;
  btnText: string;
  desc2?: string;
  clicked?: any;
};

const QuoteReceivedModal = ({
  user,
  cancel,
  desc,
  desc2,
  btnText,
  clicked,
}: QuoteReceivedModalProp) => {
  return (
    <div className="received-backdrop">
      <div
        className={`received-container ${clicked && "del-received-container"}`}
      >
        <span className="cancel-btn">
          <img onClick={cancel} src={Cancel} alt="Modal" />
        </span>
        <div>
          <img
            src={Congrat}
            alt="Congrat"
            className={clicked ? "del-img" : "u-img"}
          />
          <h3 className="mt-2 text-center">{user}</h3>
          <p className="mb-2 user-text">{desc}</p>
          {desc2 ? <p className="mb-2 user-text">{desc2}</p> : ""}

          <Button
            id="modal-btn"
            text={btnText}
            type="button"
            className="modal-btn"
            onClick={clicked ? () => clicked() : () => cancel()}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteReceivedModal;
