import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";

import ProfileImage from "../../../assets/icons/profile-placeholder.svg";
import EmailIcon from "../../../assets/icons/email-action-unread.svg";
import PhoneIcon from "../../../assets/icons/mobile-phone-blackberry-1.svg";
import BulbIcon from "../../../assets/icons/profile-bulb.svg";
import PlusIcon from "../../../assets/img/add.svg";
import { RoutePath } from "../../../constants/routes";
import EmptyJobList from "../../../components/EmptyJobList/EmptyJobList";
import Quotes from "../../../components/Quote/Quote";
import useApi from "../../../services/hooks/useApi";
import {
  GetCustomerJobs,
  GetProfile,
} from "../../../services/apiservice/generalService";
import { CustomerProfile } from "../../../models/Profile";

const CustomerDashboard = () => {
  const [notificationOption, setNotificationOption] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const getCustomerJobs = useApi<any[]>(GetCustomerJobs);
  const {
    data: profileInfo,
    fetch,
    loading: profileLoading,
  } = useApi<CustomerProfile>(GetProfile);

  useEffect(() => {
    fetch();
    getCustomerJobs.fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (profileLoading) {
      setName("Loading...");
      setPhoneNumber("Loading...");
    } else {
      setName(
        `${profileInfo?.payload?.firstName} ${profileInfo?.payload?.surName}`
      );
      setPhoneNumber(`${profileInfo?.payload?.phoneNumber}`);
    }
  }, [
    profileLoading,
    profileInfo?.payload?.firstName,
    profileInfo?.payload?.surName,
    profileInfo?.payload?.phoneNumber,
  ]);

  return (
    <div className="profile-container">
      {getCustomerJobs.data?.payload &&
      getCustomerJobs.data?.payload?.length === 0 ? (
        <EmptyJobList />
      ) : (
        <div>
          <div className="dashboard-header">
            <div className="dashboard-image">
              <img
                src={
                  profileInfo?.payload?.profilePic
                    ? profileInfo?.payload.profilePic
                    : ProfileImage
                }
                alt="ProfilePicture"
                className="image"
              />
              <div>
                <h4 className="mt-1 title">
                  {name.length > 0 ? name : "Not Provided"}
                </h4>
                <span className="emain-text">
                  <img src={EmailIcon} alt="Email" className="icons" />{" "}
                  {profileInfo?.payload?.email}
                </span>
                <span className="emain-text">
                  <img src={PhoneIcon} alt="Phone" className="icons" />{" "}
                  {phoneNumber.length > 0 ? phoneNumber : "Not Provided"}
                </span>

                <Link
                  to={RoutePath.edit_profile}
                  className="edit-button text-center"
                >
                  Edit Profile
                </Link>
              </div>
            </div>

            <div className="status">
              <img src={BulbIcon} alt="Bulb" className="bulb" />
              <div className="ms-2">
                <h3 className="profile-text mb-2">Profile Setup</h3>
                <h3
                  className={`${
                    profileInfo?.payload?.firstName
                      ? "profile-green"
                      : "profile-red"
                  }`}
                >
                  {profileInfo?.payload?.firstName ? "Complete" : "Incomplete"}
                </h3>
              </div>
            </div>
          </div>
          <div className="mt-5">
            <select
              value={notificationOption}
              className="form-select notification-dropdown"
              aria-label="Apartment Type"
              onChange={(e: any) => setNotificationOption(e.target.value)}
              placeholder="Order By"
            >
              <option value="New Notification">New Notification</option>
            </select>
          </div>

          <div className="w-100">
            <div className="mt-4 quotes-container">
              <div className="quotes-wrap">
                {getCustomerJobs.data?.payload?.map((info: any) => (
                  <Quotes
                    key={info.id}
                    name={info.projectTitle}
                    isActive={info.isActive}
                    id={info.id.toString()}
                    createdDate={info.createdDate}
                    projectID={info.projectId}
                    num={getCustomerJobs.data?.payload?.length || 0}
                  />
                ))}
                {getCustomerJobs.loading &&
                  [1, 2, 3, 4, 5].map((index) => (
                    <div className="skeleton col-3">
                      <Skeleton
                        key={index}
                        sx={{ padding: 5, marginRight: 5, marginBottom: 5 }}
                        // className="me-5"
                        variant="rectangular"
                        width={300}
                        height={"260px"}
                      />
                    </div>
                  ))}
              </div>
              <Link
                to={RoutePath.create_job}
                className="add"
                // state={{ routename: "Create-Job" }}
                onClick={() =>
                  localStorage.setItem(
                    "routename",
                    JSON.stringify("Create-Job")
                  )
                }
              >
                <img src={PlusIcon} alt="Add Icon" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDashboard;
