import React, { useContext, useState } from "react";
import "./admin.css";
import { Button, TextBox } from "../../components/customInputs/textBox";
import { CreateQuoteContext } from "../../components/AdminJobs/CreateQuoteContext/CreateQuoteContainer";

const CreateQuote4 = () => {
  const contextObject = useContext(CreateQuoteContext);
  const { updateContext, pageNumber } = contextObject;
  const [quoteName, setQuoteName] = useState("");
  const [secondColumn, setSecondColumn] = useState("Without Solar Panel");
  const [systemMaxOut1, setSystemMaxOut] = useState("");
  const [systemMaxOut2, setSystemMaxOut2] = useState("");

  const [disableField1, setDisableField1] = useState(false);
  const [disableField2, setDisableField2] = useState(false);
  const [disableMaintenanceField1, setDisableMaintenanceField1] =
    useState(false);
  const [disableMaintenanceField2, setDisableMaintenanceField2] =
    useState(false);
  const [disableLabourField1, setDisableLabourField1] = useState(false);
  const [disableLabourField2, setDisableLabourField2] = useState(false);

  const submitQuote4 = (e: any) => {
    e.preventDefault();
    updateContext({
      ...contextObject,
      pageNumber: pageNumber + 1,
    });
  };
  return (
    <>
      <div className="admin-container">
        <div className="quote-inputs-container">
          <div className="quote-inputs-details">
            <div className="quote-install-options">
              <p className="first-header mb-0 first-column">Quote Options</p>

              <div className="second-column">
                <TextBox
                  placeholder="Solar Panel"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setQuoteName(e.target.value);
                  }}
                  value={quoteName}
                  disabled
                />
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="Without Solar Panel"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut(e.target.value);
                  }}
                  value={systemMaxOut1}
                  disabled
                />
              </div>
            </div>

            <div className="quote-install-options mt-4">
              <p className="first-header mb-0 first-column">
                Others installation accessories
              </p>

              <div className="second-column text-center">
                <input
                  type="checkbox"
                  name="colFirst"
                  className="p-auto"
                  onChange={(e: any) => {
                    setDisableField1(!disableField1);
                  }}
                  checked={disableField1}
                />
              </div>

              <div className="third-column text-center">
                <input
                  type="checkbox"
                  name="colSecond"
                  onChange={(e: any) => {
                    setDisableField2(!disableField2);
                  }}
                  checked={disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Remote monitoring system cost
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 50,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 50,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container why-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Wiring and cables</p>
              </div>

              <div className="second-column quote3-why">
                <TextBox
                  placeholder="e.g 6mm AC Cable, 6mm DC Cable, 1 pcs of battery breaker box, 2x battery rack, 1x voltage regulator"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column quote3-why">
                <TextBox
                  placeholder="e.g 6mm AC Cable, 6mm DC Cable, 1 pcs of battery breaker box, 2x battery rack, 1x voltage regulator"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container why-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Breakers</p>
              </div>

              <div className="second-column quote3-why">
                <TextBox
                  placeholder="e.g 1x pcs each of 100A and 16A Ac Nada breakers 2x pcs of 100A DC Nada breakers"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column quote3-why">
                <TextBox
                  placeholder="e.g 1x pcs each of 100A and 16A Ac Nada breakers 2x pcs of 100A DC Nada breakers"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container why-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Pv support module</p>
              </div>

              <div className="second-column quote3-why">
                <TextBox
                  placeholder="e.g L-foot, end clamps, mid"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column quote3-why">
                <TextBox
                  placeholder="e.g L-foot, end clamps, mid"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container why-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">AC and DC surge protectors</p>
              </div>

              <div className="second-column quote3-why">
                <TextBox
                  placeholder="e.g 1x 240V AC Scheinder surge, 1x 600v DC Scheinder surge"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column quote3-why">
                <TextBox
                  placeholder="e.g 1x 240V AC Scheinder surge, 1x 600v DC Scheinder surge"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Total installation accessories cost
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 50,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 50,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-underline mt-4"></div>

            <div className="quote-install-options mt-4">
              <p className="first-header mb-0 first-column">Maintenance</p>

              <div className="second-column text-center">
                <input
                  type="checkbox"
                  name="colFirst"
                  onChange={(e: any) => {
                    setDisableMaintenanceField1(!disableMaintenanceField1);
                  }}
                  checked={disableMaintenanceField1}
                />
              </div>

              <div className="third-column text-center">
                <input
                  type="checkbox"
                  name="colSecond"
                  onChange={(e: any) => {
                    setDisableMaintenanceField2(!disableMaintenanceField2);
                  }}
                  checked={disableMaintenanceField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">Solar panel cleaning schedule</p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 time per year</option>
                  <option value="2">2 times per year</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 time per year</option>
                  <option value="2">2 times per year</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Solar panel cleaning cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 2,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableMaintenanceField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 2,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableMaintenanceField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">Solar panel cleaning schedule</p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 time per year</option>
                  <option value="2">2 times per year</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 time per year</option>
                  <option value="2">2 times per year</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">Inverter servicing cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 2,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableMaintenanceField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 2,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableMaintenanceField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">Battery maintenance schedule</p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 time per year</option>
                  <option value="2">2 times per year</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1 time per year</option>
                  <option value="2">2 times per year</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Battery maintenance cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g n 2,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableMaintenanceField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 2,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableMaintenanceField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">Free maintenance</p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">0</option>
                  <option value="2">1</option>
                  <option value="2">2</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableMaintenanceField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">0</option>
                  <option value="2">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            </div>

            <div className="quote-underline mt-4"></div>

            <div className="quote-install-options mt-4">
              <p className="first-header mb-0 first-column">Labour</p>

              <div className="second-column text-center">
                <input
                  type="checkbox"
                  name="colFirst"
                  onChange={(e: any) => {
                    setDisableLabourField1(!disableLabourField1);
                  }}
                  checked={disableLabourField1}
                />
              </div>

              <div className="third-column text-center">
                <input
                  type="checkbox"
                  name="colSecond"
                  onChange={(e: any) => {
                    setDisableLabourField2(!disableLabourField2);
                  }}
                  checked={disableLabourField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  How many people will install? <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableLabourField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={secondColumn}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSecondColumn(e.target.value)}
                  disabled={!disableLabourField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Logistics and transport <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableLabourField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableLabourField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Total cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 50,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableLabourField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 50,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableLabourField2}
                />
              </div>
            </div>

            <div className="quote-underline mt-4"></div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">Grand Total Cost (+VAT)</p>
              </div>

              <div className="second-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSystemMaxOut2(e.target.value);
                  }}
                  value={systemMaxOut2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <Button
              id="submit"
              type="submit"
              text="Done"
              className="onboard-button mt-5 quote-submit"
              onClick={submitQuote4}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuote4;
