import React, { useContext } from "react";
import { CreateQuoteStages } from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import { CreateQuoteContext } from "./CreateQuoteContainer";

import delIcon from "../../../assets/icons/delete-1.svg";
import backIcon from "../../../assets/icons/back-1.svg";

import "../../AdminJobs/Job.css";

const CreateQuoteContainer = () => {
  const contextObj = useContext(CreateQuoteContext);
  const { pageNumber, updateContext } = contextObj;
  const navigate = useNavigate();
  return (
    <div className="job-body">
      <div className="top-btn">
        {pageNumber === 0 ? (
          <span onClick={() => navigate(-1)} className="btn cancel-button">
            <img className="me-1 mt-0" src={delIcon} alt="delete Icon" />
            <span>Cancel</span>
          </span>
        ) : (
          <span
            onClick={() =>
              updateContext({ ...contextObj, pageNumber: pageNumber - 1 })
            }
            className="btn back-button"
          >
            <img
              className="mt-0 me-1 back-image"
              src={backIcon}
              alt="delete Icon"
            />
            Back
          </span>
        )}
        {pageNumber < 4 && (
          <div className="indicator-container">
            <div
              className={`indicator ${pageNumber >= 0 ? "quote-active" : ""}`}
            ></div>
            <div
              className={`indicator ${pageNumber >= 1 ? "quote-active" : ""}`}
            ></div>
            <div
              className={`indicator ${pageNumber >= 2 ? "quote-active" : ""}`}
            ></div>
            <div
              className={`indicator ${pageNumber >= 3 ? "quote-active" : ""}`}
            ></div>
          </div>
        )}
        <div></div>
      </div>
      <div>{CreateQuoteStages[pageNumber].component}</div>
    </div>
  );
};

export default CreateQuoteContainer;
