import React, { useContext, useEffect, useState } from "react";

import { Button, TextBox } from "../../../components/customInputs/textBox";
import InfoIcon from "../../../assets/icons/information-circle.svg";
import { JobContext } from "../../../components/jobContext/jobContainer";
import useApi from "../../../services/hooks/useApi";
import { getAllStates } from "../../../services/apiservice/generalService";

const JobList = () => {
  const contextObject = useContext(JobContext);
  const { data, fetch } = useApi<any>(getAllStates);

  const [buildingType, setApartmentType] = useState(
    contextObject.buildingType ? contextObject.buildingType : ""
  );
  const [floorCount, setNumFloors] = useState(
    contextObject.floorCount ? contextObject.floorCount : ""
  );
  const [monthlyEnergyExpenditure, setMonthlyEnergy] = useState(
    contextObject.monthlyEnergyExpenditure
      ? contextObject.monthlyEnergyExpenditure
      : ""
  );
  const [backUpHours, setBackupHours] = useState(
    contextObject.backUpHours ? contextObject.backUpHours : ""
  );
  const [generatorSize, setGeneratorSize] = useState(
    contextObject.generatorSize ? contextObject.generatorSize : ""
  );
  const [monthlyEnergyConsumption, setMonthlyEnergyConsumption] = useState(
    contextObject.monthlyEnergyConsumption
      ? contextObject.monthlyEnergyConsumption
      : ""
  );
  const [dailyPHCNBackUpHours, setDailyPHCNBackUpHours] = useState(
    contextObject.dailyPHCNBackUpHours ? contextObject.dailyPHCNBackUpHours : ""
  );
  const [stateId, setStateId] = useState(
    contextObject.stateId ? contextObject.stateId : ""
  );
  const [projectTitle, setProjectTitle] = useState(
    contextObject.projectTitle ? contextObject.projectTitle : ""
  );

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { updateContext, pageNumber } = contextObject;
  const submitForm = (e: any) => {
    e.preventDefault();
    updateContext({
      ...contextObject,
      stateId: stateId,
      monthlyEnergyExpenditure: parseInt(monthlyEnergyExpenditure),
      monthlyEnergyConsumption: parseInt(monthlyEnergyConsumption),
      backUpHours: parseInt(backUpHours),
      dailyPHCNBackUpHours: parseInt(dailyPHCNBackUpHours),
      floorCount: parseInt(floorCount),
      generatorSize: parseInt(generatorSize),
      buildingType: parseInt(buildingType),
      projectTitle: projectTitle,
      pageNumber: pageNumber + 1,
    });
  };

  return (
    <div>
      <div className="job-container">
        <h1 className="job-text">Create a Listing</h1>

        <form className="joblist-form" onSubmit={submitForm}>
          <div className="joblist-input mb-3">
            <label
              className="input-label"
              aria-label="Project Title"
              label-for="project title"
            >
              Project Title
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="e.g Femi's Residence"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setProjectTitle(e.target.value);
                }}
                value={projectTitle}
              />
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-3">
            <label
              className="input-label"
              aria-label="Apartment Type"
              label-for="apartment"
            >
              Select the type of apartment you live in
            </label>
            <div className="d-flex">
              <select
                value={buildingType}
                className="form-select dropdown me-2"
                aria-label="Apartment Type"
                onChange={(e: any) => setApartmentType(e.target.value)}
              >
                <option value="" disabled></option>
                <option value="1">Residence</option>
                <option value="2">Commercial</option>
              </select>
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-3">
            <label
              className="input-label"
              aria-label="Email Address"
              label-for="email"
            >
              Enter the number of floors you have in your home
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="e.g 10"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setNumFloors(e.target.value);
                }}
                value={floorCount}
              />
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-2">
            <label
              className="input-label"
              label-for="Monthly Energy Expenditure"
              aria-label="Monthly Energy Expenditure"
            >
              Enter your monthly energy expenditure (in naira)
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="e.g 10,000"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setMonthlyEnergy(e.target.value);
                }}
                value={monthlyEnergyExpenditure}
              />
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-2">
            <label className="input-label" aria-label="State" label-for="state">
              Select the state you are currently
            </label>
            <div className="d-flex">
              <select
                value={stateId}
                className="form-select dropdown me-2"
                aria-label="States"
                onChange={(e: any) => setStateId(e.target.value)}
              >
                {data?.payload.map(
                  ({ name, id }: { name: string; id: string }) => (
                    <option value={id}>{name}</option>
                  )
                )}
              </select>
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-2">
            <label
              className="input-label"
              label-for="Monthly Energy Consumption"
              aria-label="Monthly Energy Consumption"
            >
              Enter your monthly energy consumption (in kW)
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="e.g 5"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setMonthlyEnergyConsumption(e.target.value);
                }}
                value={monthlyEnergyConsumption}
              />
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-2">
            <label
              className="input-label"
              label-for="Daily PHCN Backup Hours"
              aria-label="Daily PHCN Backup Hours"
            >
              Enter your Daily PHCN duration of Power (in hours)
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="e.g 5"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setDailyPHCNBackUpHours(e.target.value);
                }}
                value={dailyPHCNBackUpHours}
              />
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-2">
            <label
              className="input-label"
              aria-label="Backup Hours"
              label-for="backup"
            >
              Select the hours of backup you need
            </label>
            <div className="d-flex">
              <select
                value={backUpHours}
                className="form-select dropdown me-2"
                aria-label="Backup Hours"
                onChange={(e: any) => setBackupHours(e.target.value)}
              >
                <option value="" disabled></option>
                <option value="4">4 Hours</option>
                <option value="8">8 Hours</option>
                <option value="10">10 Hours</option>
                <option value="14">14 Hours</option>
                <option value="18">18 Hours</option>
                <option value="20">20 Hours</option>
                <option value="24">24 Hours</option>
              </select>
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <div className="joblist-input mb-4">
            <label
              className="input-label"
              label-for="generator"
              aria-label="generator"
            >
              Enter the size of your generator (in Kva)
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="e.g 2"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setGeneratorSize(e.target.value);
                }}
                value={generatorSize}
              />
              <img src={InfoIcon} alt="Info" />
            </div>
          </div>

          <Button
            id="submit"
            type="submit"
            text="Next"
            className="onboard-button"
            // onClick={() => {}}
            disabled={
              buildingType &&
              floorCount.length &&
              monthlyEnergyExpenditure.length &&
              backUpHours.length &&
              generatorSize.length &&
              monthlyEnergyConsumption &&
              dailyPHCNBackUpHours &&
              stateId &&
              projectTitle
                ? false
                : true
            }
          />
        </form>
      </div>
    </div>
  );
};

export default JobList;
