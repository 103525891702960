import React from "react";

import CalenderIcon from "../../assets/img/calendar-clock.svg";
import StarRating from "./StarRating";
import { Button } from "../customInputs/textBox";

interface Props {
  name: string;
  rating: number;
  solar: string;
  withoutSolar: string;
  onClick: any;
}

const QuoteInstaller = ({
  name,
  rating,
  solar,
  withoutSolar,
  onClick,
}: Props) => {
  return (
    <div className={`border installer-quote me-2 `}>
      <img src={CalenderIcon} alt="Calender" className="calender" />
      <span className="date-text">
        26th May, 2020 <span>.</span> <span>2:30pm</span>
      </span>

      <div className="project-container mt-1">
        <h3 className="mb-0 quote-name">{name}</h3>
        <p className="project-text">Project ID: MSB/NG/SW/OGN/0015</p>
      </div>

      <div>
        <div className="svg-flex">
          <p>Star Rating</p>
          <StarRating ratingObj={rating} />
        </div>
        <div className="svg-flex">
          <p>Solar Panel</p>
          <p>{solar}</p>
        </div>
        <div className="svg-flex">
          <p>Without Solar Panel</p>
          <p>{withoutSolar}</p>
        </div>
      </div>

      <Button
        id="submit"
        type="submit"
        text="View Quote"
        className="onboard-button view-quote"
        onClick={onClick}
      />
    </div>
  );
};

export default QuoteInstaller;
