import React from "react";

import "./styles.css";
import Appliance from "../../models/Appliance";

interface Props {
  onClick?: () => void;
  url: string;
  name: string;
  id: string;
  appliances?: Appliance[];
  width?: string | number;
  quantity?: number;
}

const SingleAppliance = ({
  onClick,
  url,
  name,
  id,
  appliances,
  width,
  quantity,
}: Props) => {
  return (
    <>
      <div
        className={`equipment ${
          appliances && appliances?.findIndex((x) => x.applianceId === id) >= 0
            ? "active"
            : ""
        } ${width && "width"} `}
        onClick={onClick}
        key={id}
      >
        <img src={`${process.env.REACT_APP_BASEURL}${url}`} alt={name} />
        <p className="name">{name}</p>
      </div>
      <p className="">{quantity}</p>
    </>
  );
};

export default SingleAppliance;
