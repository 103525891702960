import React, { useState } from "react";
import QuoteReceivedModal from "./QuoteReceivedModal";
import { QuoteProgressData } from "../../constants/dummy";
import QuoteProgress from "./QuoteProgress";
import { Button } from "../customInputs/textBox";

import "../../views/customer/customer.css";

const QuotePayment = (props: any) => {
  const [notificationOption, setnotificationOption] = useState("Bank Transfer");
  const [showModal, setShowModal] = useState(false);

  return (
    <div>
      {showModal && (
        <QuoteReceivedModal
          user="Your payment has been sent successfully"
          cancel={() => setShowModal(false)}
          desc="A notification will be sent to the installer."
          btnText="Ok"
          desc2="An agreed start date for the job should be discussed."
        />
      )}
      <div className="Quote-Payment-Container">
        <div className="Quote-Progress mt-4">
          {QuoteProgressData.map((data) => (
            <QuoteProgress
              key={data.id}
              name={data.name}
              imgUrl={data.imgUrl}
              active={data.active}
            />
          ))}
        </div>

        <div className="details-container">
          <p className="congratText">
            <span className="underleft">Congrats</span>. The SLA has been
            accepted by the installer. Please proceed to payment.
          </p>

          <div>
            <p className="dropdown-label">Select a payment option</p>

            <div className="mt-1 quote-dropdown-container">
              <select
                value={notificationOption}
                className="form-select quote-dropdown"
                aria-label="Payment Option"
                onChange={(e: any) => setnotificationOption(e.target.value)}
              >
                <option value="Bank Transfer">Bank Transfer</option>
                <option value="Card Payment">Card Payment</option>
              </select>
            </div>
          </div>
        </div>
        <div className="mt-5 Bank-Details">
          <h3 className="bank-name">Mysolarbid</h3>
          <div className="acc-number">
            <p>Account Number</p>
            <p>1019087654</p>
          </div>
          <div className="acc-number mt-0">
            <p>Bank</p>
            <p>Zenith Bank Plc</p>
          </div>
        </div>

        <Button
          id="submit"
          type="submit"
          text="Submit"
          className="onboard-button mt-4 quote-submit"
          onClick={() => setShowModal(true)}
        />
      </div>
    </div>
  );
};

export default QuotePayment;
