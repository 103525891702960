import React, { useState, useEffect } from "react";
import "../../customer/customer.css";
import backIcon from "../../../assets/icons/back-1.svg";
import { Button, TextBox } from "../../../components/customInputs/textBox";
import Facebook from "../../../assets/img/facebook.svg";
import Twitter from "../../../assets/img/twitter.svg";
import Instagram from "../../../assets/img/instagram.svg";
import Google from "../../../assets/img/google.svg";
import { Link, useNavigate } from "react-router-dom";
import { RoutePath } from "../../../constants/routes";
import { AuthService } from "../../../services/apiservice/authService";

interface Props {
  loginData: any;
}
export const Login = ({ loginData }: Props) => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const { login, isSuccess, loading, error } = AuthService();
  const navigate = useNavigate();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    // login({ email, password });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
  };

  useEffect(() => {
    loginData();
  });

  useEffect(() => {
    if (isSuccess) {
      navigate(RoutePath.customer_dashboard);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  return (
    <div className="signup-container">
      <span onClick={() => navigate(-1)} className="btn desktop">
        <img className="m-2 mt-0" src={backIcon} alt="delete Icon" />
        Back
      </span>
      <div className="login-form-container">
        <h1 className="form-header">
          Login
          <span className="header-dot">.</span>
        </h1>
        <Link
          to={RoutePath.customer_signup}
          className="text-black text-decoration-none"
        >
          <h3 className="form-sub">Signup</h3>
        </Link>
        <form className="login-onboard-form">
          <p className="text-danger mb-0 input-label">{error}</p>
          <div className="group-input">
            <label
              className="input-label"
              aria-label="Email Address"
              label-for="email"
            >
              Email address
            </label>
            <TextBox
              placeholder="Enter your email address"
              type="email"
              id="email"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setEmail(e?.target?.value);
              }}
              value={email}
            />
          </div>
          <div className="group-input">
            <label
              className="input-label"
              label-for="password"
              aria-label="Create passwords"
            >
              Password
            </label>
            <TextBox
              placeholder="Enter your Password"
              type="Password"
              id="password"
              required
              onChange={handleChange}
              value={password}
            />
          </div>
          <Button
            id="submit"
            type="submit"
            text="Login"
            className="onboard-button"
            onClick={handleClick}
            disabled={password && email && !loading ? false : true}
          />
          <div className="text-end">
            <Link to={RoutePath.forgot_password} className="forgot-text">
              Forgot password?
            </Link>
          </div>
          <div className="text-center term-text mt-2">
            <p className="rline"></p>
            <p className="text"> Or login with</p>
            <p className="lline"></p>
          </div>
        </form>

        <div className="social-container">
          <img src={Facebook} alt="Facebook" className="left social-img" />
          <img src={Google} alt="Google" className="social-img" />
          <img src={Instagram} alt="Instagram" className="social-img" />
          <img src={Twitter} alt="Twitter" className="social-img" />
        </div>
        <div className="base-question">
          <p>
            Do not have an account?{" "}
            <Link
              to={RoutePath.customer_signup}
              className="text-black text-decoration-none"
            >
              <span>Signup</span>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
};
export default Login;
