import React from "react";
import "./style.css";

import Close from "../../../assets/img/close.png";
import createQuoteImage from "../../../assets/img/Installer-Modal.png";
import { Button } from "../../customInputs/textBox";

type Props = {
  close: () => void;
};

const CreateQuoteBackdrop = ({ close }: Props) => {
  return (
    <div className="quote-received-backdrop">
      <div className="quote-received-container">
        <span className="bg-warning quote-cancel-backdrop" onClick={close}>
          <img src={Close} alt="Create Quote" />
        </span>

        <div>
          <img src={createQuoteImage} alt="Quote" className="u-img" />
          <h3 className="mt-2">Create a quote from:</h3>
          <form className="mb-4">
            <div>
              <input type="checkbox" id="vehicle1" name="new-quote" value="1" />{" "}
              <label htmlFor="new-quote"> New Quote</label>
            </div>
            <br />
            <div>
              <input
                type="checkbox"
                id="vehicle2"
                name="saved-template"
                value="2"
              />{" "}
              <label htmlFor="saved-template"> Saved quote template</label>
            </div>
          </form>

          <Button
            id="modal-btn"
            text="Ok"
            type="button"
            className="quote-modal-btn"
            onClick={() => {}}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateQuoteBackdrop;
