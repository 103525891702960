import React, { FunctionComponent, useState, useContext } from "react";
import { CalculatorContext } from "../../components/calculatorContainer/containerContext";

import "./calculator.css";
import {
    Button,
    SuggestionButtons,
    TextBox,
} from "../../components/customInputs/textBox";

export const CalcBackupHourComponent: FunctionComponent = () => {
    const contextObject = useContext(CalculatorContext);
    const { updateContext, pageNumber } = contextObject;
    const [backupHours, setBackupHours] = useState<number>(
        contextObject?.backupHours || 1
    );

    const submitElectricityHours = (e: any) => {
        e.preventDefault();
        updateContext({
            ...contextObject,
            backupHours,
            pageNumber: pageNumber + 1,
        });
    };

    const HourSuggestions = [
        { label: "4 hours", value: 4 },
        { label: "8 hours", value: 8 },
        { label: "10 hours", value: 10 },
        { label: "14 hours", value: 14 },
        { label: "18 hours", value: 18 },
        { label: "20 hours", value: 20 },
        { label: "24 hours", value: 24 },
    ];

    const handleBackupHours = (e: any) => {
        if (e.target.value) {
            setBackupHours(parseInt(e.target.value));
        }
    };

    const handleSuggestionClick = (hours: number) => {
        setBackupHours(hours);
    };

    return (
        <div className="innersector-container">
            <h1 className="question font-48 mt-2">
                How many hours of backup do you need?
            </h1>
            <p className="sub-question">
                please enter a duration you want your battery to last for the
                selected appliances.
            </p>
            <form
                className="innersector-container"
                onSubmit={submitElectricityHours}
            >
                <TextBox
                    placeholder="Number of hours"
                    type="number"
                    id="elec-hours"
                    value={backupHours}
                    className="answer-box mb-1"
                    onChange={handleBackupHours}
                    max={24}
                    min={1}
                    unit="Hours"
                />
                <Button
                    text="Next"
                    id="elec-button"
                    className="calculator-button"
                    disabled={backupHours ? false : true}
                    // onClick={() => console.log("calcBackupHour.tsx")}
                />
                <div className="suggest-box">
                    {HourSuggestions.map(({ label, value }, index) => {
                        return (
                            <SuggestionButtons
                                key={`${label}-${index}`}
                                type="button"
                                onClick={() => handleSuggestionClick(value)}
                                id={`${index}-${label}`}
                                text={label}
                            />
                        );
                    })}
                </div>
            </form>
        </div>
    );
};
export default CalcBackupHourComponent;
