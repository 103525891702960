import React, { useContext, useState } from "react";
import "./admin.css";
import { Button, TextBox } from "../../components/customInputs/textBox";
import { CreateQuoteContext } from "../../components/AdminJobs/CreateQuoteContext/CreateQuoteContainer";

const CreateQuote2 = () => {
  const contextObject = useContext(CreateQuoteContext);
  const { updateContext, pageNumber } = contextObject;
  const [solarManufacturer1, setSolarManufacturer1] = useState("");
  const [solarManufacturer2, setSolarManufacturer2] = useState("");

  const [solarType1, setSolarType1] = useState("Select an option");
  const [solarType2, setSolarType2] = useState("Select an option");

  const [solarRating1, setSolarRating1] = useState("");
  const [solarRating2, setSolarRating2] = useState("");

  const [solarQuantity1, setSolarQuantity1] = useState("");
  const [solarQuantity2, setSolarQuantity2] = useState("");

  const [solarUnitPrice1, setSolarUnitPrice1] = useState("");
  const [solarUnitPrice2, setSolarUnitPrice2] = useState("");

  const [solarWarranty1, setSolarWarranty1] = useState("Select an option");
  const [solarWarranty2, setSolarWarranty2] = useState("Select an option");

  const [solarPanelCost1, setSolarPanelCost1] = useState("");
  const [solarPanelCost2, setSolarPanelCost2] = useState("");

  const [inverterManufacturer1, setInverterManufacturer1] = useState("");
  const [inverterManufacturer2, setInverterManufacturer2] = useState("");

  const [inverterType1, setInverterType1] = useState("Select an option");
  const [inverterType2, setInverterType2] = useState("Select an option");

  const [inverterVoltage1, setInverterVoltage1] = useState("");
  const [inverterVoltage2, setInverterVoltage2] = useState("");

  const [inverterRating1, setInverterRating1] = useState("");
  const [inverterRating2, setInverterRating2] = useState("");

  const [inverterQuantity1, setInverterQuantity1] = useState("");
  const [inverterQuantity2, setInverterQuantity2] = useState("");

  const [inverterUnitPrice1, setInverterUnitPrice1] = useState("");
  const [inverterUnitPrice2, setInverterUnitPrice2] = useState("");

  const [inverterWarranty1, setInverterWarranty1] =
    useState("Select an option");
  const [inverterWarranty2, setInverterWarranty2] =
    useState("Select an option");

  const [inverterCost1, setInverterCost1] = useState("");
  const [inverterCost2, setInverterCost2] = useState("");

  const [disableField1, SetDisableField1] = useState(false);
  const [disableField2, SetDisableField2] = useState(false);
  const [disableInverterField1, SetDisableInverterField1] = useState(false);
  const [disableInverterField2, SetDisableInverterField2] = useState(false);

  const submitQuote2 = (e: any) => {
    e.preventDefault();
    updateContext({
      ...contextObject,
      pageNumber: pageNumber + 1,
    });
  };

  return (
    <>
      <div className="admin-container">
        <div className="quote-inputs-container">
          <div className="quote-inputs-details">
            <div className="quote-install-options">
              <p className="first-header mb-0 first-column">Quote Options</p>

              <div className="second-column">
                <TextBox
                  placeholder="Solar Panel"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={() => {}}
                  value={""}
                  disabled
                />
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="Without Solar Panel"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={() => {}}
                  value={""}
                  disabled
                />
              </div>
            </div>

            <div className="mt-3">
              <p className="text-center mb-0 quote2-header">
                Click on the checkboxes to enable or disable fields as necessary
              </p>
              <div className="quote-underline"></div>
            </div>

            <div className="quote-install-options mt-4">
              <p className="first-header mb-0 first-column">Solar Panels</p>

              <div className="second-column text-center">
                <input
                  type="checkbox"
                  name="colFirst"
                  onChange={(e: any) => {
                    SetDisableField1(!disableField1);
                  }}
                  checked={disableField1}
                />
              </div>

              <div className="third-column text-center">
                <input
                  type="checkbox"
                  name="colSecond"
                  onChange={(e: any) => {
                    SetDisableField2(!disableField2);
                  }}
                  checked={disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Solar panel manufacturer <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g jinko"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarManufacturer1(e.target.value);
                  }}
                  value={solarManufacturer1}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g Jinko"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarManufacturer2(e.target.value);
                  }}
                  value={solarManufacturer2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Solar panel type <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={solarType1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSolarType1(e.target.value)}
                  disabled={!disableField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">Monocrystalline</option>
                  <option value="2">Polycrystalline</option>
                  <option value="3">Others</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={solarType2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSolarType2(e.target.value)}
                  disabled={!disableField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">Monocrystalline</option>
                  <option value="2">Polycrystalline</option>
                  <option value="3">Others</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Solar panel rating <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 245 Watts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarRating1(e.target.value);
                  }}
                  value={solarRating1}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 245 Watts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarRating2(e.target.value);
                  }}
                  value={solarRating2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Solar panel quantity <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 25"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarQuantity1(e.target.value);
                  }}
                  value={solarQuantity1}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 25"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarQuantity2(e.target.value);
                  }}
                  value={solarQuantity2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Solar panel unit price <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 25,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarUnitPrice1(e.target.value);
                  }}
                  value={solarUnitPrice1}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 25,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarUnitPrice2(e.target.value);
                  }}
                  value={solarUnitPrice2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Solar panel warranty <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={solarWarranty1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSolarWarranty1(e.target.value)}
                  disabled={!disableField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={solarWarranty2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setSolarWarranty2(e.target.value)}
                  disabled={!disableField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Total panel cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarPanelCost1(e.target.value);
                  }}
                  value={solarPanelCost1}
                  disabled={!disableField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setSolarPanelCost2(e.target.value);
                  }}
                  value={solarPanelCost2}
                  disabled={!disableField2}
                />
              </div>
            </div>

            <div className="quote-underline mt-4"></div>

            <div className="quote-install-options mt-4">
              <p className="first-header mb-0 first-column">Inverter</p>

              <div className="second-column text-center">
                <input
                  type="checkbox"
                  name="colFirst"
                  onChange={(e: any) => {
                    SetDisableInverterField1(!disableInverterField1);
                  }}
                  checked={disableInverterField1}
                />
              </div>

              <div className="third-column text-center">
                <input
                  type="checkbox"
                  name="colSecond"
                  onChange={(e: any) => {
                    SetDisableInverterField2(!disableInverterField2);
                  }}
                  checked={disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Inverter manufacturer <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g Luminous"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterManufacturer1(e.target.value);
                  }}
                  value={inverterManufacturer1}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g Luminous"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterManufacturer2(e.target.value);
                  }}
                  value={inverterManufacturer2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Inverter rating <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 5 Kilowatts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterRating1(e.target.value);
                  }}
                  value={inverterRating1}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 5 Kilowatts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterRating2(e.target.value);
                  }}
                  value={inverterRating2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="quote-title">
                  Inverter voltage <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 24 Volts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterVoltage1(e.target.value);
                  }}
                  value={inverterVoltage1}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 24 Volts"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterVoltage2(e.target.value);
                  }}
                  value={inverterVoltage2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Inverter quantity <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g 5"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterQuantity1(e.target.value);
                  }}
                  value={inverterQuantity1}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g 5"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterQuantity2(e.target.value);
                  }}
                  value={inverterQuantity2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Inverter type (hybrid?) <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={inverterType1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setInverterType1(e.target.value)}
                  disabled={!disableInverterField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={inverterType2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setInverterType2(e.target.value)}
                  disabled={!disableInverterField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">Yes</option>
                  <option value="2">No</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">
                  Inverter unit price <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterUnitPrice1(e.target.value);
                  }}
                  value={inverterUnitPrice1}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  placeholder="e.g N 20,000"
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterUnitPrice2(e.target.value);
                  }}
                  value={inverterUnitPrice2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column pay-options-text">
                <p className="quote-title">
                  Inverter warranty <span>*</span>
                </p>
              </div>

              <div className="second-column">
                <select
                  value={inverterWarranty1}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setInverterWarranty1(e.target.value)}
                  disabled={!disableInverterField1}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="6">&gt;5</option>
                </select>
              </div>

              <div className="third-column">
                <select
                  value={inverterWarranty2}
                  className="form-select create-quote-dropdown"
                  aria-label="quotes"
                  onChange={(e: any) => setInverterWarranty2(e.target.value)}
                  disabled={!disableInverterField2}
                >
                  <option value="">Select an option</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="5">5</option>
                  <option value="5">&gt;5</option>
                </select>
              </div>
            </div>

            <div className="quote-table-container mt-4">
              <div className="first-column">
                <p className="mb-0 quote-title">Total inverter cost</p>
              </div>

              <div className="second-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterCost1(e.target.value);
                  }}
                  value={inverterCost1}
                  disabled={!disableInverterField1}
                />
              </div>

              <div className="third-column">
                <TextBox
                  type="text"
                  id="text"
                  className="input-width me-2 input-radius"
                  required
                  onChange={(e: any) => {
                    setInverterCost2(e.target.value);
                  }}
                  value={inverterCost2}
                  disabled={!disableInverterField2}
                />
              </div>
            </div>

            <Button
              id="submit"
              type="submit"
              text="Save and Continue"
              className="onboard-button mt-5 quote-submit"
              onClick={submitQuote2}
              disabled={!disableField1 || !disableInverterField1}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateQuote2;
