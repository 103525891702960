import React from "react";

import { Button } from "../../../components/customInputs/textBox";
import CloseSVG from "../../../assets/img/close.png";

import "./styles.css";

interface Props {
  close: any;
}

const QuoteBackdrop = ({ close }: Props) => {
  return (
    <div className="quote-backdrop">
      <div className="quote-container">
        <span className="bg-warning cancel-backdrop" onClick={() => close()}>
          <img src={CloseSVG} alt="Cancel" className="cancel-img" />
        </span>

        <div className="mt-5 backdrop-details">
          <p className="fs-3">Installer</p>
          <div className="install-options">
            <p className="first">Quote Options</p>
            <p>Solar Panel</p>
            <p>Without Solar Panel</p>
          </div>

          <div className="underline"></div>

          <div className="table-container mt-4">
            <div className="first">
              <p>Solar Panels</p>
              <p>Solar Panels Type</p>
              <p>Solar Panels Manufacturer</p>
            </div>

            <div className="second">
              <p>15 pcs of 310 Wp</p>
              <p>Monocrystalline</p>
              <p>Auxano </p>
            </div>

            <div className="third">
              <p>None</p>
            </div>
          </div>

          <div className="underline"></div>

          <div className="table-container mt-4">
            <div className="first">
              <p>Inverter quanity</p>
              <p>Inverter Type</p>
              <p>Inverter Manufacturer</p>
            </div>

            <div className="second">
              <p>2</p>
              <p>Hybrid</p>
              <p>Luminous </p>
            </div>

            <div className="third">
              <p>2</p>
              <p>Hybrid</p>
              <p>Luminous</p>
            </div>
          </div>

          <div className="underline"></div>

          <div className="table-container mt-4">
            <div className="first">
              <p>Battery quantity</p>
              <p>Battery volatge</p>
              <p>Battery type</p>
              <p>Battery manufacturer</p>
            </div>

            <div className="seconds">
              <p>12</p>
              <p>12v / 200Ah</p>
              <p>AGM </p>
              <p>Luminous </p>
            </div>

            <div className="thirds">
              <p>10</p>
              <p>12v / 200Ah</p>
              <p>Lithium ion</p>
              <p>Luminous</p>
            </div>
          </div>

          <div className="underline"></div>

          <div className="table-container mt-4">
            <div className="first">
              <p>Charge controller quantity</p>
              <p>Charge controller rating</p>
              <p>Charge controller type</p>
              <p>Charge controller manufacturer</p>
            </div>

            <div className="second">
              <p>1</p>
              <p>60 A</p>
              <p>MPPT</p>
              <p>jinko</p>
            </div>

            <div className="third">
              <p>None</p>
            </div>
          </div>

          <div className="underline"></div>

          <div className="table-container mt-4">
            <div className="first">
              <p>Initial deposit ratio</p>
              <p>Final deposit ratio</p>
            </div>

            <div className="second">
              <p>20%</p>
              <p>80%</p>
            </div>

            <div className="third">
              <p>70%</p>
              <p>30%</p>
            </div>
          </div>

          <div className="underline"></div>

          <p className="mb-0 mt-3">Outright Purchase</p>
          <div className="table-container mt-1">
            <div className="first">
              <p>Total cost (incl. VAT)</p>
              <p>Payment term</p>
              <p>Initial deposit</p>
              <p>Final deposit</p>
              <p>Maintenance fee (optional)</p>
            </div>

            <div className="second">
              <p>3,311,000.00</p>
              <p>80% : 20%</p>
              <p>2,648,800.00</p>
              <p>662,200.00</p>
              <p>75,000.00</p>
            </div>

            <div className="third">
              <p>2,268,250.00</p>
              <p>70% : 30%</p>
              <p>1,814,600.00</p>
              <p>453,650.00</p>
              <p>75,000.00</p>
            </div>
          </div>

          <div className="underline"></div>

          <p className="mb-0 mt-3">Payment plan</p>
          <p className="mb-0 mt-0 vat-text">Total cost (incl. VAT)</p>
          <div className="table-container mt-1">
            <div className="first">
              <p>Monthly payments</p>
              <p>Initial</p>
              <p>Payment paln term (months)</p>
              <p>Maintenance fee (optional)</p>
            </div>

            <div className="second">
              <p>330,000.00</p>
              <p>990,000.00</p>
              <p>60</p>
              <p>20,000.00</p>
            </div>

            <div className="third">
              <p>165,000.00</p>
              <p>495,000.00</p>
              <p>60</p>
              <p>20,000.00</p>
            </div>
          </div>
          <Button
            id="submit"
            type="submit"
            text="Close"
            className="onboard-button mt-5 quote-submit"
            onClick={() => close()}
          />
        </div>
      </div>
    </div>
  );
};

export default QuoteBackdrop;
