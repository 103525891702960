import React, { useState, useEffect } from "react";
import CreateQuoteContainer from ".";
export const CreateQuoteContext = React.createContext();

const CreateQuoteContainerContext = ({ children }) => {
  const [contextObj, setContextObj] = useState({ pageNumber: 0 });

  const updateContext = (object) => {
    setContextObj({ ...contextObj, ...object });
  };

  useEffect(() => {
    let createQuote = localStorage.getItem("create-quote");
    if (createQuote) setContextObj(JSON.parse(createQuote));
  }, []);

  useEffect(() => {
    localStorage.setItem("create-quote", JSON.stringify(contextObj));
  }, [contextObj]);
  return (
    <div>
      <CreateQuoteContext.Provider value={{ ...contextObj, updateContext }}>
        <CreateQuoteContainer>{children}</CreateQuoteContainer>
      </CreateQuoteContext.Provider>
    </div>
  );
};

export default React.memo(CreateQuoteContainerContext);
