import React, { useContext, useEffect, useState } from "react";
import { Button, TextBox } from "../../../components/customInputs/textBox";
import { useNavigate } from "react-router-dom";
import { RoutePath } from "../../../constants/routes";
import { JobContext } from "../../../components/jobContext/jobContainer";
import useApi from "../../../services/hooks/useApi";
import {
  CreateJob,
  EditJob,
} from "../../../services/apiservice/generalService";
import { AppToast } from "../../../components/Toast/toast";

const JobVerification = () => {
  const contextObject = useContext(JobContext);
  const createJob = useApi(CreateJob);
  let id = JSON.parse(localStorage.getItem("jobID") as string);
  const editJob = useApi(() => EditJob(id));
  console.log(id);

  const [name, setName] = useState(
    contextObject.name ? contextObject.name : ""
  );
  const [phoneNumber, setPhoneNumber] = useState(
    contextObject.phoneNumber ? contextObject.phoneNumber : ""
  );
  const [address, setAddress] = useState(
    contextObject.address ? contextObject.address : ""
  );
  const navigate = useNavigate();

  let routeurl = JSON.parse(localStorage.getItem("routename") as string);

  let {
    stateId,
    monthlyEnergyConsumption,
    dailyPHCNBackUpHours,
    buildingType,
    floorCount,
    monthlyEnergyExpenditure,
    backUpHours,
    generatorSize,
    jobAppliances,
    projectTitle,
    pageNumber,
    updateContext,
  } = contextObject;

  const submitForm = (e: any) => {
    e.preventDefault();
    if (routeurl === "Create-Job") {
      createJob.fetch({
        name,
        phoneNumber,
        address,
        projectTitle,
        stateId,
        monthlyEnergyExpenditure,
        monthlyEnergyConsumption,
        backUpHours,
        dailyPHCNBackUpHours,
        floorCount,
        generatorSize,
        buildingType,
        jobAppliances,
      });
    } else {
      editJob.fetch({
        id,
        name,
        phoneNumber,
        address,
        stateId,
        monthlyEnergyExpenditure,
        monthlyEnergyConsumption,
        backUpHours,
        dailyPHCNBackUpHours,
        floorCount,
        generatorSize,
        buildingType,
        jobAppliances,
      });
    }
  };

  useEffect(() => {
    if (editJob.isSuccess) {
      navigate(RoutePath.quote_details);
      AppToast({ type: "success", message: "Job Updated Successfully" });
      updateContext({
        ...contextObject,
        pageNumber: pageNumber - 2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editJob.isSuccess]);

  useEffect(() => {
    if (createJob.isSuccess) {
      AppToast({ type: "success", message: "Job Created Successfully" });
      navigate(RoutePath.customer_dashboard);
      updateContext({
        ...contextObject,
        pageNumber: pageNumber - 2,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createJob.isSuccess]);

  return (
    <div className="p-3">
      <div className="job-container">
        <h1 className="job-text mb-4">Verify your info.</h1>

        <form className="joblist-form" onSubmit={submitForm}>
          <div className="joblist-input mb-3">
            <label className="input-label" aria-label="Name" label-for="name">
              Enter your First Name & Last Name
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="Anthony Oparah"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setName(e.target.value);
                }}
                value={name}
              />
            </div>
          </div>

          <div className="joblist-input mb-2">
            <label
              className="input-label"
              label-for="phone number"
              aria-label="Phone Number"
            >
              Phone Number
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="***********"
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setPhoneNumber(e.target.value);
                }}
                value={phoneNumber}
              />
            </div>
          </div>

          <div className="joblist-input mb-4">
            <label
              className="input-label"
              label-for="address"
              aria-label="address"
            >
              Project address (Number, Street, State)
            </label>
            <div className="d-flex">
              <TextBox
                placeholder="13, Oremeji Street, Igando, Lagos."
                type="text"
                id="text"
                className="input-width me-2"
                required
                onChange={(e: any) => {
                  setAddress(e.target.value);
                }}
                value={address}
              />
            </div>
          </div>

          <Button
            id="submit"
            type="submit"
            text={`${
              routeurl === "Create-Job" ? "Create Job listing" : "Update Job"
            }`}
            className="onboard-button"
            disabled={
              name.length &&
              address.length &&
              phoneNumber.length &&
              !createJob.loading &&
              !editJob.loading
                ? false
                : true
            }
          />
        </form>
      </div>
    </div>
  );
};

export default JobVerification;
