import JobCreated from "../assets/icons/add-circle.svg";
import QuoteReceived from "../assets/icons/check-circle-1.svg";
import QuoteAccepted from "../assets/icons/like-shine.svg";
import AgreementReview from "../assets/icons/common-file-text-info.svg";
import AgreementFinal from "../assets/icons/common-file-text-check.svg";
import PaymentFinal from "../assets/icons/common-file-cash.svg";
import InstallSchedule from "../assets/icons/calendar-check.svg";
import InstallDone from "../assets/icons/cog-approved.svg";
import JobDone from "../assets/icons/certified-ribbon.svg";
import PaymentComplete from "../assets/icons/cog-approved.svg";

export const data = [
  {
    id: 1,
    name: "Femi Residence",
    isActive: true,
  },
  {
    id: 2,
    name: "Benji Residence",
    isActive: false,
  },
  {
    id: 3,
    name: "Benji Residence",
    isActive: false,
  },
  {
    id: 4,
    name: "Benji Residence",
    isActive: false,
  },
  {
    id: 5,
    name: "Benji Residence",
    isActive: false,
  },
];

export const Installer = [
  {
    id: "a",
    name: "Installer 1",
    star: 4,
    solarPanel: "400,000.00",
    withoutSolarPanel: "250,000.00",
    show: false,
  },
  {
    id: "b",
    name: "Installer 2",
    star: 3,
    solarPanel: "400,000.00",
    withoutSolarPanel: "200,000.00",
    show: false,
  },
  {
    id: "c",
    name: "Installer 3",
    star: 2,
    solarPanel: "700,000.00",
    withoutSolarPanel: "300,000.00",
    show: false,
  },
];

export const QuoteProgressData = [
  {
    id: 1,
    name: "Job Created",
    imgUrl: JobCreated,
    active: true,
  },
  {
    id: 2,
    name: "Quote Received",
    imgUrl: QuoteReceived,
    active: true,
  },
  {
    id: 3,
    name: "Quote Accepted",
    imgUrl: QuoteAccepted,
    active: false,
  },
  {
    id: 4,
    name: "Agreement Review",
    imgUrl: AgreementReview,
    active: false,
  },
  {
    id: 5,
    name: "Agreement Finalisation",
    imgUrl: AgreementFinal,
    active: false,
  },
  {
    id: 6,
    name: "Payment Finalisation",
    imgUrl: PaymentFinal,
    active: false,
  },
  {
    id: 7,
    name: "Installation Scheduled",
    imgUrl: InstallSchedule,
    active: false,
  },
  {
    id: 8,
    name: "Installation Done",
    imgUrl: InstallDone,
    active: false,
  },
  {
    id: 9,
    name: "Job Done",
    imgUrl: JobDone,
    active: false,
  },
  {
    id: 10,
    name: "Payment Completed",
    imgUrl: PaymentComplete,
    active: false,
  },
];

export const QuoteReceivedData = [
  {
    id: 1,
    name: "Job Created",
    imgUrl: JobCreated,
    active: true,
  },
  {
    id: 2,
    name: "Quote Received",
    imgUrl: QuoteReceived,
    active: true,
  },
  {
    id: 3,
    name: "Quote Accepted",
    imgUrl: QuoteAccepted,
    active: true,
  },
  {
    id: 4,
    name: "Agreement Review",
    imgUrl: AgreementReview,
    active: false,
  },
  {
    id: 5,
    name: "Agreement Finalisation",
    imgUrl: AgreementFinal,
    active: false,
  },
  {
    id: 6,
    name: "Payment Finalisation",
    imgUrl: PaymentFinal,
    active: false,
  },
  {
    id: 7,
    name: "Installation Scheduled",
    imgUrl: InstallSchedule,
    active: false,
  },
  {
    id: 8,
    name: "Installation Done",
    imgUrl: InstallDone,
    active: false,
  },
  {
    id: 9,
    name: "Job Done",
    imgUrl: JobDone,
    active: false,
  },
  {
    id: 10,
    name: "Payment Completed",
    imgUrl: PaymentComplete,
    active: false,
  },
];

export const Jobs = [
  {
    id: "job1",
    name: "Rufai Abdul-Qudus",
  },
  {
    id: "job2",
    name: "Rufai Abdul-Qudus",
  },
  {
    id: "job3",
    name: "Rufai Abdul-Qudus",
  },
  {
    id: "job4",
    name: "Rufai Abdul-Qudus",
  },
];

export const BiddedJobs = [
  {
    id: "bid1",
    name: "Rufai Abdul-Qudus",
  },
  {
    id: "bid2",
    name: "Rufai Abdul-Qudus",
  },
];
