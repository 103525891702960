export default class CalculatorModel{
    email: string;
    dailyPeakElecHours: number;
    dailyBackupHours: number;
    state: string;
    monthlyEnergyExpenditure: string;
    applianceIds : string[];

    constructor(email: string, dailyPeakElecHours: number, dailyBackupHours:number, state: string, monthlyEnergyExpenditure:string, appliances: any){
            this.email = email;
            this.dailyPeakElecHours = dailyPeakElecHours;
            this.dailyBackupHours = dailyBackupHours;
            this.state = state;
            this.monthlyEnergyExpenditure = monthlyEnergyExpenditure;
            this.applianceIds  = appliances.map(({ applianceId}: {applianceId: string})=> applianceId);
    }
}

export interface CalculatorResultModel {
    isExistingUser : boolean;
    data: object[]| [];
}