import React from "react";
import Star from "../../assets/icons/Star.svg";
import StarFilled from "../../assets/icons/Star2.svg";

type Props = {
  ratingObj: number;
};
const StarRating = ({ ratingObj }: Props) => {
  let stars = [];
  // Loop 5 times
  for (var i = 1; i <= 5; i++) {
    let path = Star;
    if (i > ratingObj) {
      path = StarFilled;
    }
    stars.push(path);
  }
  return (
    <div className="star-container">
      {stars.map((star) => (
        <img src={star} alt="star" className="svg" />
      ))}
    </div>
  );
};

export default StarRating;
