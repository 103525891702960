import React, { useEffect, useState } from "react";
import JobContainer from ".";
export const JobContext = React.createContext();

const JobContextComponent = ({ children }) => {
  const [contextObj, setContextObj] = useState({ pageNumber: 0 });

  const updateContext = (object) => {
    setContextObj({ ...contextObj, ...object });
  };

  useEffect(() => {
    let job = localStorage.getItem("job");
    if (job) setContextObj(JSON.parse(job));
  }, []);

  useEffect(() => {
    localStorage.setItem("job", JSON.stringify(contextObj));
  }, [contextObj]);
  return (
    <div>
      <JobContext.Provider value={{ ...contextObj, updateContext }}>
        <JobContainer>{children}</JobContainer>
      </JobContext.Provider>
    </div>
  );
};

export default React.memo(JobContextComponent);
