import React, { useMemo, useRef, useState } from "react";
import "../../views/customer/customer.css";
import "./admin.css";
import { Link } from "react-router-dom";
import ReactElasticCarousel from "react-elastic-carousel";

import { BiddedJobs, Jobs } from "../../constants/dummy";
import useWindowDimensions from "../../utility/hooks";
import ProfileImage from "../../assets/icons/profile-placeholder.svg";
import EmailIcon from "../../assets/icons/email-action-unread.svg";
import PhoneIcon from "../../assets/icons/mobile-phone-blackberry-1.svg";
import BulbIcon from "../../assets/icons/profile-bulb.svg";
import { RoutePath } from "../../constants/routes";
import Job from "../../components/AdminJobs/Job";
import BiddedJob from "../../components/AdminJobs/BiddedJob";

const navList = [
  { label: "Available Jobs", isActive: true },
  { label: "Bidded Jobs", isActive: false },
];

const AdminDashboard = () => {
  const [activeNav, setActiveNav] = useState("Available Jobs");
  const [notificationOption, setnotificationOption] = useState("Sort By");
  const [biddedOptions, setBiddedOptions] = useState("Sort By");
  const slideRef = useRef<any>();
  const { width } = useWindowDimensions();

  const handleNavClick = (label: string) => {
    setActiveNav(label);
  };

  let _navList = useMemo(() => {
    let activeNavItem = navList.findIndex((x) => x.isActive === true);
    if (activeNavItem >= 0) {
      navList[activeNavItem] = {
        ...navList[activeNavItem],
        isActive: false,
      };
    }
    let index = navList.findIndex((x) => x.label === activeNav);
    navList[index] = { ...navList[index], isActive: true };
    return navList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNav]);

  return (
    <div className="admin-container">
      <div className="dashboard-header">
        <div className="dashboard-image">
          <img src={ProfileImage} alt="DashboardImage" className="image" />
          <div>
            <h4 className="mt-1 title">Company Name</h4>
            <span className="emain-text">
              <img src={EmailIcon} alt="Email" className="icons" />{" "}
              anthony@gmail.com
            </span>
            <span className="emain-text">
              <img src={PhoneIcon} alt="Phone" className="icons" /> 09050990008
            </span>

            <Link
              to={RoutePath.edit_profile}
              className="edit-button text-center"
            >
              Edit Profile
            </Link>
          </div>
        </div>

        <div className="status">
          <img src={BulbIcon} alt="Bulb" className="bulb" />
          <div className="ms-2">
            <h3 className="profile-text mb-2">Profile Setup</h3>
            <h3
              className="
                  profile-green"
            >
              Complete
            </h3>
          </div>
        </div>
      </div>

      <div className="admin-categories">
        {_navList.map(({ isActive, label }, index) => {
          return (
            <span
              key={label}
              className={`admin-item ${isActive ? "active" : ""}`}
              onClick={() => handleNavClick(label)}
            >
              <p>{label}</p>
              <span className="admin-underline"></span>
            </span>
          );
        })}
      </div>

      {activeNav === "Available Jobs" ? (
        <>
          <div className="install-container mt-3">
            <div className="job-dropdown-container">
              <select
                value={notificationOption}
                className="form-select admin-dropdown"
                aria-label="Job Completed"
                onChange={(e: any) => setnotificationOption(e.target.value)}
              >
                <option value="">Sort By</option>
                <option value="1">Most Recent</option>
              </select>
            </div>
          </div>
          <div className="mt-3">
            <label className="job-time mt-2">
              26th May, 2023 <span>Expires today</span>
            </label>
            <ReactElasticCarousel
              isRTL={false}
              showArrows={false}
              itemsToShow={
                width > 1600
                  ? 5
                  : width > 1100
                  ? 2.6
                  : width > 770
                  ? 1.8
                  : width > 450
                  ? 1.4
                  : 1.1
              }
              ref={slideRef}
              pagination={false}
            >
              {Jobs.map((job) => (
                <Job name={job.name} key={job.id} />
              ))}
            </ReactElasticCarousel>
          </div>

          <label className="job-time mt-2">
            26th May, 2023 <span>Expires today</span>
          </label>
          <ReactElasticCarousel
            isRTL={false}
            showArrows={false}
            itemsToShow={
              width > 1600
                ? 5
                : width > 1100
                ? 2.6
                : width > 770
                ? 1.8
                : width > 450
                ? 1.4
                : 1.1
            }
            ref={slideRef}
            pagination={false}
          >
            {Jobs.map((job) => (
              <Job name={job.name} key={job.id} />
            ))}
          </ReactElasticCarousel>
        </>
      ) : (
        <>
          <div className="install-container mt-3">
            <div className="job-dropdown-container">
              <select
                value={biddedOptions}
                className="form-select admin-dropdown"
                aria-label="Job Completed"
                onChange={(e: any) => setBiddedOptions(e.target.value)}
              >
                <option value="">Sort By</option>
                <option value="1">Completed</option>
                <option value="2">New</option>
              </select>
            </div>
          </div>
          <div className="mt-3 bidded-jobs">
            {BiddedJobs.map((bidded) => (
              <BiddedJob />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default AdminDashboard;
