const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

// const INSTALLER_URL =  "/Installers";
// const JOBS_URL = "/jobs";
const USERS_URL = "Users";
const JOBS_URL = "Jobs";
const SHARED_URL = "Shared";
const CALCULATOR = "Calculator/Calculate";
export const apiRoutes = {
  //Shared APIs
  GetAllAppliances: () => baseApiUrl + SHARED_URL + "/GetAllAppliances",

  GetAllStates: () => baseApiUrl + SHARED_URL + "/GetAllStates",

  //Calculator
  EstimateCost: () => baseApiUrl + CALCULATOR,

  CreateUserEndpoint: () => baseApiUrl + USERS_URL + "/CreateUser",

  LoginUserEndpoint: () => baseApiUrl + USERS_URL + "/Login",

  ResendConfirmationEmail: (email) =>
    baseApiUrl + USERS_URL + `/ResendEmailLink?email=${email}`,

  CreateJob: () => baseApiUrl + JOBS_URL + "/Create",

  GetCustomerJobs: () => baseApiUrl + JOBS_URL + "/GetCustomerJobs",
  GetProfile: () => baseApiUrl + USERS_URL + "/GetProfile",

  UpdateProfile: () => baseApiUrl + USERS_URL + "/UpdateProfile",

  ChangePassword: () => baseApiUrl + USERS_URL + "/ChangePassword",

  UploadProfilePicture: () => baseApiUrl + USERS_URL + "/UploadProfilePic",

  DeleteJob: (id) => baseApiUrl + JOBS_URL + `/Delete/${id}`,

  GetCustomerJobById: (id) => baseApiUrl + JOBS_URL + `/GetCustomerJob/${id}`,

  EditJob: (id) => baseApiUrl + JOBS_URL + `/Update/${id}`,
};
