import React, { useState } from "react";
import "./dashboard.css";

import MsbLogo from "../../assets/img/msblogo.png";
import NavMenu from "../../assets/img/navigation-menu-1.png";
import Sidebar from "../Sidebar/index";
import { Navigate, Outlet } from "react-router";
import { RoutePath } from "../../constants/routes";
import { isAuth } from "../../services/apiservice/authService";
import { Toaster } from "react-hot-toast";

const AuthLayout = () => {
  const [showSideBar, setShowSideBar] = useState(false);
  const handleSideBAr = () => {
    setShowSideBar(!showSideBar);
  };
  return isAuth() ? (
    <div className="container-fluid p-0">
      <Toaster />
      <div className="nav-container">
        <a className="logo-sidebar" href={RoutePath.home}>
          <img src={MsbLogo} alt="My Solar bid" />
        </a>
        <span></span>
        <span onClick={handleSideBAr} className="menu">
          <img src={NavMenu} alt="Menu" />
        </span>
      </div>
      <div>
        {showSideBar && <Sidebar closeSideBar={() => setShowSideBar(false)} />}
        <Outlet />
      </div>
    </div>
  ) : (
    <Navigate to={{ pathname: RoutePath.customer_login }} />
  );
};
export default AuthLayout;
