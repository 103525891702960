import axios from "axios";
import { getToken } from "../apiservice/authService";

export interface RequestProps {
  url: string;
  type: "get" | "post" | "put" | "delete";
  data?: any;
  isAuth?: boolean;
  noStringify?: boolean;
  downloadFile?: boolean;
  contentType?: string;
}

export interface DataProps {
  payload: any;
  code: number;
  description: string;
  result: number;
  totalCount: number;
}

export const request: Function = ({
  url,
  type,
  data = null,
  isAuth = true,
  noStringify = false,
  contentType = "Application/json",
  downloadFile = false,
}: RequestProps) => {
  let REQUEST_URL = url;
  let bodyData;
  let service: Promise<any>;
  bodyData = noStringify ? JSON.stringify(data) : data;
  let config: any;

  if (downloadFile) {
    config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      responseType: "arraybuffer",
    };
  } else if (isAuth) {
    config = {
      headers: {
        "Content-type": contentType,
        Authorization: `Bearer ${getToken()}`,
      },
    };
  } else {
    config = {
      headers: {
        "Content-type": "Application/json",
        Accept: "application/json",
      },
    };
  }

  if (type === "get") {
    service = axios.get(REQUEST_URL, config);
    return service
      .then(function (response) {
        return service;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            //destroy client auth details and log user out
          } else {
            return service;
          }
        }
        return service;
      });
  } else if (type === "post") {
    service = axios.post(REQUEST_URL, bodyData, config);
    return service
      .then(function (response) {
        return service;
      })
      .catch(function (error) {
        if (error.response) {
          if (error.response.status === 401) {
            //destroy client auth details and log user out
          } else {
            return service;
          }
        }
        return service;
      });
  } else if (type === "put") {
    service = axios.put(REQUEST_URL, bodyData, config);
    return service
      .then(() => service)
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            //destroy client auth details and log user out
          } else {
            return service;
          }
        }
        return service;
      });
  } else {
    service = axios.delete(REQUEST_URL, config);
    return service
      .then(() => service)
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            //destroy client auth details and log user out
          } else {
            return service;
          }
        }
        return service;
      });
  }
};

export default request;
