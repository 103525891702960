import CalculatorModel from "../../models/calculator";
import { apiRoutes } from "../apiroutes";
import { RequestProps } from "../core";

const baseObject = { isAuth: false };
export const getAllStates = (): RequestProps => {
  return { url: apiRoutes.GetAllStates(), type: "get", ...baseObject };
};

export const getAllAppliances = (): RequestProps => {
  return { url: apiRoutes.GetAllAppliances(), type: "get", ...baseObject };
};

export const estimateCost = (data: CalculatorModel): RequestProps => {
  return { url: apiRoutes.EstimateCost(), type: "post", data, ...baseObject };
};

export const createUser = (data: any): RequestProps => {
  return {
    url: apiRoutes.CreateUserEndpoint(),
    type: "post",
    data,
    ...baseObject,
  };
}; //  createUser in onboarding service

export const LoginUser = (data: any): RequestProps => {
  return {
    url: apiRoutes.LoginUserEndpoint(),
    type: "post",
    data,
    ...baseObject,
  };
};

export const CreateJob = (data: any): RequestProps => {
  return { url: apiRoutes.CreateJob(), type: "post", data, ...baseObject };
};

export const GetCustomerJobs = (data: any): RequestProps => {
  return { url: apiRoutes.GetCustomerJobs(), type: "get", data, ...baseObject };
};
export const UpdateProfile = (data: any): RequestProps => {
  return { url: apiRoutes.UpdateProfile(), type: "put", data, ...baseObject };
};

export const GetProfile = (): RequestProps => {
  return { url: apiRoutes.GetProfile(), type: "get" };
};

export const ChangeUserPassword = (data: any): RequestProps => {
  return { url: apiRoutes.ChangePassword(), type: "post", data, ...baseObject };
};

export const UploadProfilePicture = (data: any): RequestProps => {
  return {
    url: apiRoutes.UploadProfilePicture(),
    type: "post",
    data,
    contentType: "multipart/form-data",
  };
};

export const DeleteJob = (id: string): RequestProps => {
  return { url: apiRoutes.DeleteJob(id), type: "delete", ...baseObject };
};

export const GetCustomerJobByID = (id: string): RequestProps => {
  return { url: apiRoutes.GetCustomerJobById(id), type: "get", ...baseObject };
};

export const EditJob = (id: string): RequestProps => {
  return { url: apiRoutes.EditJob(id), type: "put", ...baseObject };
};
