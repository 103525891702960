import React, { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { RoutePath } from "../../constants/routes";

const navList = [
  { label: "Home", isActive: true, href: "#home" },
  { label: "How it Works", isActive: false, href: "#howItWorks" },
  { label: "Why MySolarBid", isActive: false, href: "#whyMySolarBid" },
  { label: "About Us", isActive: false, href: "#aboutUs" },
];
export const Navbar = ({ focusProp }) => {
  const [activeNav, setActiveNav] = useState("#home");
  //   const [_navList, setNavList] = useState(navList);

  const handleNavClick = (href) => {
    setActiveNav(href);
  };

  const handleLogin = (e) => {
    e.preventDefault();
   
  };

  useEffect(() => {
    setActiveNav(focusProp);
  }, [focusProp]);

  let _navList = useMemo(() => {
    let activeNavItem = navList.findIndex((x) => x.isActive === true);
    if (activeNavItem >= 0) {
      navList[activeNavItem] = {
        ...navList[activeNavItem],
        isActive: false,
      };
    }
    let index = navList.findIndex((x) => x.href === activeNav);
    navList[index] = { ...navList[index], isActive: true };
    return navList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeNav]);

  return (
    <section className="nav-section">
      <nav className="nav-nav align-items-center">
        {_navList.map(({ href, isActive, label }, index) => {
          return (
            <a
              key={`${href}-${index}`}
              className={`nav-item desktop ${isActive ? "active fw-bold" : ""}`}
              href={href}
              onClick={() => handleNavClick(href)}
            >
              <label className="textStyle">{label}</label>
              <span className="pos">
                <span className="left m-0"></span>
                <span className="right"></span>
              </span>
            </a>
          );
        })}
        <Link to={RoutePath.customer_signup} className="btn home-button pe-auto" id="homeButton">SIGN IN/SIGN UP</Link>
        {/* <a
          href="/#"
          onClick={(e) => handleLogin(e)}
          className="btn home-button pe-auto"
          id="homeButton"
        >
          <i className="fontStyles fw-bold">SIGN IN/SIGN UP</i>
        </a> */}
      </nav>
    </section>
  );
};

export default Navbar;
