import React, { useState } from "react";

import { Button, TextBox } from "../customInputs/textBox";
import { QuoteProgressData } from "../../constants/dummy";

import QuoteProgress from "./QuoteProgress";
import QuoteReceivedModal from "./QuoteReceivedModal";

import "../../views/customer/customer.css";

const QuoteComplete = () => {
  const [notificationOption, setnotificationOption] = useState("Yes");
  const [installDate, setInstallDate] = useState("");
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");

  return (
    <div>
      {showCompleteModal && (
        <QuoteReceivedModal
          user="You have successfully accepted your favourite installer"
          cancel={() => setShowCompleteModal(false)}
          btnText="Done"
        />
      )}
      <div className="Quote-Payment-Container">
        <div className="Quote-Progress mt-4">
          {QuoteProgressData.map((data) => (
            <QuoteProgress
              key={data.id}
              name={data.name}
              imgUrl={data.imgUrl}
              active={data.active}
            />
          ))}
        </div>

        <div className="job-complete-container">
          <div className="install-container">
            <p className="install-text">Installation Date</p>
            <TextBox
              placeholder="25th August 2021"
              type="text"
              id="text"
              className="install-input me-2"
              required
              onChange={(e: any) => {
                setInstallDate(e.target.value);
              }}
              value={installDate}
            />
          </div>

          <div className="install-container mt-3">
            <p className="job-text">Job Completed</p>
            <div className="job-dropdown-container">
              <select
                value={notificationOption}
                className="form-select job-dropdown"
                aria-label="Job Completed"
                onChange={(e: any) => setnotificationOption(e.target.value)}
              >
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </select>
            </div>
          </div>

          <div className="install-container mt-3">
            <p className="job-text">Image upload</p>
            <div className="job-dropdown-container">
              <label htmlFor="image" className="uploadLablel">
                <input
                  type="file"
                  id="image"
                  className="uploadButton"
                  onChange={(e: any) => setSelectedImage(e.target.value)}
                  value={selectedImage}
                />
                Upload Image
              </label>
            </div>
          </div>
        </div>

        <Button
          id="submit"
          type="submit"
          text="Submit"
          className="onboard-button mt-5 upload-submit"
          onClick={() => {
            setShowCompleteModal(true);
          }}
        />
      </div>
    </div>
  );
};

export default QuoteComplete;
