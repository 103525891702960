import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./home.css";

import Helment from "../../assets/img/helment.png";
import CornerBox from "../../assets/icons/corner_box.svg";
import Medal from "../../assets/img/medal.png";
import { RoutePath } from "../../constants/routes";

import LightningImg from "../../assets/img/lighting.png";
import BinocularsImg from "../../assets/img/binoculars.png";
import HelpDeskImg from "../../assets/img/help_desk.png";
import InfoImg from "../../assets/img/info.png";
import MedalImg from "../../assets/img/medal.png";
import PaymentImg from "../../assets/img/payment.png";
import MsbLogo from "../../assets/img/msblogo.png";

import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LogoIcon from "../../components/svgIcons/logo";
import Navbar from "../NavBar";

class HomeComponent extends Component {
    state = {
        activeBar: "",
    };

    handleFocus = (id: string) => {
        this.setState({ activeBar: id });
    };

    render() {
        const { activeBar } = this.state;
        return (
            <div className="container-fluid p-0">
                <div className="nav-bar fixed-top bg-white">
                    <a className="logo-link" href="\">
                        <img src={MsbLogo} alt="My Solar bid" />
                    </a>
                    <Navbar focusProp={activeBar} />
                </div>
                <div
                    className="home-banner row mt-3 ms-0"
                    onMouseEnter={() => this.handleFocus("#home")}
                    id="home"
                >
                    <div className="col-lg-6 col-md-6 col-sm-12 pr-5 lefts">
                        <h1 className="banner-text fw-bold">
                            Guaranteed{" "}
                            <span className="underline">quality</span> and{" "}
                            <span className="underline">financing</span> options
                            for your solar solutions
                        </h1>
                        <label className="d-block banner-tag roboto">
                            Save up to 70% on your monthly power costs with
                            MySolarBid
                        </label>
                        <Link
                            to={RoutePath.calculator}
                            className="btn mb-3 mobile"
                            id="calcButton"
                        >
                            <i className="fontStyle fw-bold button-text fw-bold">
                                Try the solar calculator
                            </i>
                        </Link>
                        <span className="mt-1">
                            <h2 className="fw-bold">
                                Work better. Live happier.
                            </h2>
                        </span>
                    </div>
                    <div className="col-lg-5 col-md-6 col-sm-12 right desktop mt-5">
                        <div className="dark-circle">
                            <img src={Helment} alt="Mysolarbid" />
                        </div>
                    </div>
                </div>
                {/* How it works */}
                <div
                    className="m-0 home-section"
                    id="howItWorks"
                    onMouseEnter={() => this.handleFocus("#howItWorks")}
                >
                    <div className="black-box"></div>
                    <div className="section-container">
                        <span className="header headers">
                            <img
                                className="right"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                            <h3>How it works</h3>
                            <img
                                className="left"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                        </span>

                        <iframe
                            className="media-player"
                            // width="1280"
                            // height="853"
                            src="https://www.youtube.com/embed/L-hJPXlcXts"
                            title="How it works | MySolarBid"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            // allowFullscreen
                        ></iframe>
                        <p className="fw-bold fs-5">Sign up as:</p>
                        <div className="mb-2">
                            <button
                                className="btn home-button fw-bold fs-5"
                                id="media-btn"
                            >
                                A Customer
                            </button>
                            <button
                                className="btn home-button fw-bold fs-5"
                                id="media-btn"
                            >
                                An Installer
                            </button>
                        </div>
                    </div>
                </div>
                {/* Going Solar is Easy */}
                <div className="home-banner">
                    <div className="inner-banner">
                        <span className="header headers mb-2 mt-5">
                            <img
                                className="right"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                            <h3>Going Solar is as easy as...</h3>
                            <img
                                className="left"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                        </span>
                        <div className="card-ctn pt-3 row">
                            <div className="card text-center p-3 steps-card col-sm-6 col-md-4 col-lg-3">
                                <h3 className="mb-3">1</h3>
                                <span>Estimate Your Consumption</span>
                            </div>
                            <div className="card text-center p-3 steps-card col-sm-6 col-md-4 col-lg-3">
                                <h3 className="mb-3">2</h3>
                                <span>Get a Quote</span>
                            </div>
                            <div className="card text-center p-3 steps-card col-sm-6 col-md-4 col-lg-3">
                                <h3 className="mb-3">3</h3>
                                <span>Decide your plan</span>
                            </div>
                            <div className="card text-center p-3 steps-card col-sm-6 col-md-4 col-lg-3">
                                <h3 className="mb-3">4</h3>
                                <img
                                    className="medal_img"
                                    src={Medal}
                                    alt="medal"
                                />
                                <span>Start Saving</span>
                            </div>
                        </div>

                        <span className="p-5">
                            <Link
                                to={RoutePath.calculator}
                                className="btn m-2 mb-0 home-button mobile"
                                id="paddingZero"
                            >
                                <i className="fw-bold button-text">
                                    Try the solar calculator
                                </i>
                            </Link>
                        </span>
                    </div>
                </div>
                {/* Why my Solarbid */}
                <div
                    className="m-0 home-section"
                    onMouseEnter={() => this.handleFocus("#whyMySolarBid")}
                    id="whyMySolarBid"
                >
                    <div className="black-box"></div>
                    <div className="section-container">
                        <span className="header headers">
                            <img
                                className="right"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                            <h3>Why MySolarBid</h3>
                            <img
                                className="left"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                        </span>
                        <label className="roboto light-font about-text">
                            The best clean technology and cheapest financing
                            option from a pool of verified installers closest to
                            you
                        </label>
                        <div className="row adv-container">
                            <section className="col-lg-6 col-sm-12 border-right">
                                <div className="adv-item">
                                    <span className="card">
                                        <img
                                            className="img-icons"
                                            src={LightningImg}
                                            alt="Savings on Energy Cost"
                                        />
                                    </span>
                                    <h5>Savings on Energy Cost</h5>
                                    <label className="roboto light-font">
                                        Installers are able to save up to 20% on
                                        soft costs there by ensuring that
                                        customers will get a better deal.
                                    </label>
                                </div>
                                <div className="adv-item">
                                    <span className="card">
                                        <img
                                            className="img-icons"
                                            src={BinocularsImg}
                                            alt="Savings on Energy Cost"
                                        />
                                    </span>
                                    <h5>Remote Monitoring</h5>
                                    <label className="roboto light-font">
                                        For preventive system diagnostics and
                                        maintenance.
                                    </label>
                                </div>
                                <div className="adv-item mb-0">
                                    <span className="card">
                                        <img
                                            className="img-icons"
                                            src={InfoImg}
                                            alt="Savings on Energy Cost"
                                        />
                                    </span>
                                    <h5>Unbiased Information</h5>
                                    <label className="roboto light-font">
                                        Free and unbiased information to help
                                        customer's decision.
                                    </label>
                                </div>
                            </section>
                            <section className="col-lg-6 col-sm-12">
                                <div className="adv-item">
                                    <span className="card">
                                        <img
                                            className="img-icons"
                                            src={PaymentImg}
                                            alt="Savings on Energy Cost"
                                        />
                                    </span>
                                    <h5>Flexible Payment Plan</h5>
                                    <label className="light-font">
                                        Customers can pick a desired payment
                                        plan for the solar units installed.
                                    </label>
                                </div>
                                <div className="adv-item">
                                    <span className="card">
                                        <img
                                            className="img-icons"
                                            src={MedalImg}
                                            alt="Savings on Energy Cost"
                                        />
                                    </span>
                                    <h5>Quality Guaranteed</h5>
                                    <label className="light-font">
                                        Access to thoroughly verified installers
                                        to ensure quality and speedy delivery.
                                    </label>
                                </div>
                                <div className="adv-item">
                                    <span className="card">
                                        <img
                                            className="img-icons"
                                            src={HelpDeskImg}
                                            alt="Savings on Energy Cost"
                                        />
                                    </span>
                                    <h5>Customer Support</h5>
                                    <label className="light-font">
                                        Excellent customer support.
                                    </label>
                                </div>
                            </section>
                        </div>
                        <span className="p-5">
                            <Link
                                to={RoutePath.calculator}
                                className="btn m-2 mb-0 home-button mobile"
                                id="paddingZero"
                            >
                                <i className="fw-bold button-text">
                                    Try the solar calculator
                                </i>
                            </Link>
                        </span>
                    </div>
                </div>
                {/* About Us */}
                <div
                    id="aboutUs"
                    onMouseEnter={() => this.handleFocus("#aboutUs")}
                    className="home-banner pt-0"
                >
                    <div className="inner-banner">
                        <span className="header headers mt-5">
                            <img
                                className="right"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                            <h3>About Us</h3>
                            <img
                                className="left"
                                src={CornerBox}
                                alt="Corner Box"
                            />
                        </span>
                        <label className="about-text">
                            MySolarbid is Nigeria's premier online solar
                            comparison platform for accessing free competitive
                            solar quotes from verified installers, quality solar
                            installations and affordable financing.
                        </label>
                        <div className="contact-box">
                            <h5 className="mt-5 mb-2 fw-bold">Contact Us</h5>
                            <a
                                href="tel:+234-816-016-0762"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <span className="card border-0 text-center icon">
                                    <PhoneIcon fontSize="small" />
                                </span>
                                <label>+234-816-016-0762</label>
                            </a>
                            <a
                                href="mailto:info@mysolarbid.com"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <i className="card border-0 text-center icon">
                                    <EmailIcon fontSize="small" />
                                </i>
                                <label>info@mysolarbid.com</label>
                            </a>
                            <h5 className="mt-4 mb-2 fw-bold">Social Media</h5>
                            <a
                                href="https://twitter.com/bid_solar"
                                target="_blank"
                                rel="noreferrer"
                                className="sc-link"
                            >
                                <span className="card border-0 text-center icon">
                                    <TwitterIcon fontSize="small" />
                                </span>
                            </a>
                            <a
                                href="https://facebook.com/mysolarbid/"
                                target="_blank"
                                rel="noreferrer"
                                className="sc-link"
                            >
                                <span className="card border-0 text-center icon">
                                    <FacebookIcon fontSize="small" />
                                </span>
                            </a>
                            <a
                                href="https://www.linkedin.com/company/mysolarbid"
                                target="_blank"
                                rel="noreferrer"
                                className="sc-link"
                            >
                                <span className="card border-0 text-center icon">
                                    <LinkedInIcon fontSize="small" />
                                </span>
                            </a>
                            <a
                                href="https://www.instagram.com/social.mysolarbid/"
                                target="_blank"
                                rel="noreferrer"
                                className="sc-link"
                            >
                                <span className="card p-0 border-0 text-center icon">
                                    <InstagramIcon fontSize="small" />
                                </span>
                            </a>
                        </div>
                        <LogoIcon />
                    </div>
                </div>
                <footer className="footer">
                    Copyright 2021 <span className="logo-text">My</span>Solar
                    <span className="logo-text">bid</span>. All Rights Reserved
                </footer>
            </div>
        );
    }
}

export default HomeComponent;
