import React from "react";
import { Link, useNavigate } from "react-router-dom";

import BackIcon from "../../assets/icons/back-1.svg";
import HourGlass from "../../assets/icons/emptylist.svg";
import { RoutePath } from "../../constants/routes";

function EmptyJobList() {
  const navigate = useNavigate();
  return (
    <div>
      <span
        onClick={() => navigate(-1)}
        className="btn desktop align-items-center"
      >
        <img className="m-2 mt-0" src={BackIcon} alt="delete Icon" />
        Back
      </span>
      <div className="empty-container">
        <img src={HourGlass} className="hourglass" alt="Empty List" />
        <h2 className="empty-text mt-3">No Job Listed</h2>
        <span className="text-center">
          You currently do not have any job listing listed.
        </span>
        <Link
          to={RoutePath.create_job}
          className="empty-button mt-5 text-center"
        >
          Create a Job Listing
        </Link>
      </div>
    </div>
  );
}

export default EmptyJobList;
