import toast from "react-hot-toast";

interface ToastProps{
    type : "success" | "info" | "error";
    message: string;
}
export const AppToast =({type, message}:ToastProps)=>{

    const notify = () =>
    toast(message, {
      duration: 4000,
      position: "top-center",
      icon: "👏",
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  const notifyError = () =>
    toast.error(message, {
      duration: 10000,
      position: "top-center",
      icon: "🤔",
      iconTheme: {
        primary: "#000",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });

    switch(type){
        case "success":
            notify();
        break;
        case "error":
            notifyError();
            break;
        default:
            notify();
            break;
    }

}