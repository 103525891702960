import { useCallback, useState } from "react";
import { AppToast } from "../../components/Toast/toast";
import request from "../core";

interface ApiResponse<T> {
  payload?: T;
  code: number;
  result: number;
  totalCount: number;
  description?: string;
}

const useApi = <T>(apiFunc: Function, isAuth = true) => {
  const [data, setData] = useState<ApiResponse<T> | null>(null);
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);

  const fetch = useCallback(
    async (rest?: any) => {
      setLoading(true);
      try {
        const result = await request({ ...apiFunc(), isAuth, data: rest });
        setData(result.data);
        setIsSuccess(true);
        setIsFailed(false);
        if (result.data.code !== 1) {
          setError(result?.data?.description);
          AppToast({
            type: "error",
            message: result?.data?.description || "Something went wrong",
          });
          setIsSuccess(false);
          setIsFailed(true);
        }
      } catch (err: any) {
        setData(null);
        setIsFailed(true);
        setIsSuccess(false);
        setError(
          err?.response?.data?.description || err.message || "Unexpected error!"
        );
        AppToast({ type: "error", message: error || "Something went wrong" });
      } finally {
        setLoading(false);
        setIsSuccess(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [apiFunc]
  );

  return {
    data,
    error,
    loading,
    isSuccess,
    isFailed,
    fetch,
  };
};

export default useApi;
