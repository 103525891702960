import React, { FunctionComponent, useState, useContext, useMemo } from "react";
import { CalculatorContext } from "../../components/calculatorContainer/containerContext";
import { Button } from "../../components/customInputs/textBox";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";

import "./calculator.css";
import State from "../../models/state";

export const CalcStateComponent: FunctionComponent = () => {
    const contextObject = useContext(CalculatorContext);
    const { updateContext, pageNumber, states } = contextObject;
    const [state, SetState] = useState<State | null>(
        contextObject?.state ? contextObject.state : null
    );
    let stateNigeria: State[];

    stateNigeria = useMemo(() => {
        let _state = states?.data?.payload.map(
            ({ name }: { name: string }) => new State(name)
        );
        return _state;
    }, [states?.data?.payload]);

    const submitCity = (e: any) => {
        e.preventDefault();
        updateContext({
            ...contextObject,
            state: state,
            pageNumber: pageNumber + 1,
        });
    };

    const handleStateChange = (event: any, value: State | null) => {
        SetState(value);
    };

    return (
        <div className="innersector-container">
            <h1 className="question font-48 mt-2">Where do you live?</h1>
            <p className="sub-question">
                To recommend a nearby installer for you.
            </p>
            <form className="innersector-container" onSubmit={submitCity}>
                <Autocomplete
                    disablePortal
                    value={state}
                    onChange={handleStateChange}
                    id="combo-box-demo"
                    options={stateNigeria || []}
                    sx={{ width: 300 }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label="State"
                                placeholder="Enter your State"
                            />
                        );
                    }}
                />
                <Button
                    text="Next"
                    id="state-button"
                    className="calculator-button"
                    disabled={state ? false : true}
                    // onClick={() => console.log("calcState.tsx")}
                />
            </form>
        </div>
    );
};

export default CalcStateComponent;
